define("zerorisk-frontend/utils/constants/compromise-task-statuses", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.COMPROMISE_TASK_STATUSES = void 0;

  var _COMPROMISE_TASK_STAT;

  var COMPROMISE_TASK_STATUSES = {
    READY: 'READY',
    CLAIMED: 'CLAIMED',
    COMPLETED: 'COMPLETED'
  };
  _exports.COMPROMISE_TASK_STATUSES = COMPROMISE_TASK_STATUSES;

  var _default = (_COMPROMISE_TASK_STAT = {}, (0, _defineProperty2.default)(_COMPROMISE_TASK_STAT, COMPROMISE_TASK_STATUSES.READY, {
    friendly: 'To Do',
    btnClass: 'btn-info',
    icon: 'circle-thin'
  }), (0, _defineProperty2.default)(_COMPROMISE_TASK_STAT, COMPROMISE_TASK_STATUSES.CLAIMED, {
    friendly: 'In Progress',
    btnClass: 'btn-primary',
    icon: 'circle-o-notch'
  }), (0, _defineProperty2.default)(_COMPROMISE_TASK_STAT, COMPROMISE_TASK_STATUSES.COMPLETED, {
    friendly: 'Completed',
    btnClass: 'btn-success',
    icon: 'check'
  }), _COMPROMISE_TASK_STAT);

  _exports.default = _default;
});