define("zerorisk-frontend/components/ember-remodal", ["exports", "ember-remodal/components/ember-remodal"], function (_exports, _emberRemodal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _emberRemodal.default;
    }
  });
});