define("zerorisk-frontend/initializers/current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    var service = Ember.ObjectProxy.create({
      isServiceFactory: true
    });
    application.register('service:current-user', service, {
      instantiate: false,
      singleton: true
    });
    application.inject('route', 'currentUser', 'service:current-user');
    application.inject('controller', 'currentUser', 'service:current-user');
    application.inject('component', 'currentUser', 'service:current-user');
    application.inject('serializer', 'currentUser', 'service:current-user');
    application.inject('adapter', 'currentUser', 'service:current-user');
  }

  var _default = {
    name: 'current-user',
    initialize: initialize
  };
  _exports.default = _default;
});