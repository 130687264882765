define("zerorisk-frontend/templates/components/merchants/filters-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZrmHXrk9",
    "block": "{\"symbols\":[\"filter\",\"@parameters\",\"@queryFilter\"],\"statements\":[[7,\"div\"],[11,\"class\",\"p-t-20 p-b-20 m-l-40 m-r-40 d-flex filters-view\"],[9],[0,\"\\n\"],[4,\"each\",[[22,3,[]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"merchants/filters/filter-tag\",null,[[\"parameters\",\"filter\",\"removeFilter\"],[[22,2,[]],[22,1,[]],[27,\"action\",[[22,0,[]],\"deleteFilter\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n  \"],[1,[27,\"merchants/filters/add-filter\",null,[[\"parameters\",\"steps\",\"currentStep\",\"filter\",\"queryFilter\",\"nextStep\",\"applyFilter\",\"selectAttributeFilter\",\"selectOperatorFilter\",\"selectOptionFilter\",\"resetFilter\"],[[22,2,[]],[22,0,[\"steps\"]],[22,0,[\"currentStep\"]],[22,0,[\"attributeFilter\"]],[22,3,[]],[27,\"action\",[[22,0,[]],\"nextStep\"],null],[27,\"action\",[[22,0,[]],\"applyFilter\"],null],[27,\"action\",[[22,0,[]],\"selectAttributeFilter\"],null],[27,\"action\",[[22,0,[]],\"selectOperatorFilter\"],null],[27,\"action\",[[22,0,[]],\"selectOptionFilter\"],null],[27,\"action\",[[22,0,[]],\"resetFilter\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/merchants/filters-view.hbs"
    }
  });

  _exports.default = _default;
});