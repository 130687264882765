define("zerorisk-frontend/mirage/factories/user", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    email: function email() {
      return _emberCliMirage.faker.internet.email();
    },
    firstName: function firstName() {
      return _emberCliMirage.faker.name.firstName();
    },
    lastName: function lastName() {
      return _emberCliMirage.faker.name.lastName();
    },
    enabled: true,
    roles: [{
      roleType: 'ROLE_MERCHANT_ENTITY_ADMIN'
    }],
    privileges: ['CAN_USE_MERCHANT'],
    merchantStatus: 'Enrolled',
    customizationId: 1
  });

  _exports.default = _default;
});