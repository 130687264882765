define("zerorisk-frontend/templates/components/location-entity-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LgaIFRo9",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[7,\"label\"],[11,\"for\",\"\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[[27,\"concat\",[\"pinpoint.dynamic.location.\",[22,0,[\"customization\",\"mpa\",\"labelMapping\",\"location\"]]],null]],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"input\"],[12,\"class\",[28,[\"form-control \",[27,\"if\",[[22,0,[\"displayErrors\"]],\"is-invalid\"],null]]]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"openLocationEntitySelectorModal\"],null]],[12,\"placeholder\",[27,\"t\",[[27,\"concat\",[\"pinpoint.dynamic.choose_location.\",[22,0,[\"customization\",\"mpa\",\"labelMapping\",\"choose_location\"]]],null]],null]],[12,\"value\",[28,[[23,[\"model\",\"entity\",\"contactInfo\",\"address\",\"tag\"]]]]],[12,\"disabled\",[22,0,[\"disabled\"]]],[11,\"type\",\"search\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[22,0,[\"displayErrors\"]]],null,{\"statements\":[[4,\"each\",[[22,0,[\"errors\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"invalid-feedback\"],[9],[1,[22,1,[\"message\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"\\n\"],[1,[27,\"inline-server-errors\",null,[[\"model\"],[[23,[\"model\",\"errors\",\"entity\"]]]]],false],[0,\"\\n\\n\"],[1,[27,\"modals/location-entity-selector-modal\",null,[[\"mode\",\"onClose\",\"onLocationClick\"],[\"locationSelection\",[27,\"action\",[[22,0,[]],\"onModalClose\"],null],[27,\"pipe-action\",[[27,\"action\",[[22,0,[]],\"onUpdateLocation\"],null],[27,\"action\",[[22,0,[]],\"closeLocationEntitySelectorModal\"],null]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/location-entity-input.hbs"
    }
  });

  _exports.default = _default;
});