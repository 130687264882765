define("zerorisk-frontend/adapters/application", ["exports", "ember-data", "ember-ajax/mixins/ajax-support", "zerorisk-frontend/utils/paths"], function (_exports, _emberData, _ajaxSupport, _paths) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend(_ajaxSupport.default, {
    host: _paths.host,
    namespace: "".concat(_paths.namespace, "/merchant"),
    coalesceFindRequests: true,
    ajaxOptions: function ajaxOptions() {
      var hash = this._super.apply(this, arguments);

      hash.contentType = 'application/json; charset=utf-8';
      hash.crossDomain = true;
      hash.xhrFields = {
        withCredentials: true
      };
      return hash;
    }
  });

  _exports.default = _default;
});