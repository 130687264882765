define("zerorisk-frontend/templates/components/risk-score-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+ogL5/d4",
    "block": "{\"symbols\":[\"@scoreType\",\"@scoreValue\"],\"statements\":[[7,\"div\"],[11,\"class\",\"risk-score-progress-container\"],[9],[0,\"\\n\"],[4,\"bs-tooltip\",null,[[\"placement\",\"triggerEvents\"],[\"top\",\"hover\"]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"padding-5 d-flex align-items-center\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[[27,\"concat\",[\"mpa.merchant.security.\",[22,1,[]]],null]],null],false],[0,\" (\"],[1,[27,\"if\",[[22,2,[]],[22,2,[]],\"Not Available\"],null],false],[0,\")\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\"],[11,\"class\",\"risk-score-label text-left m-b-5\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[[27,\"concat\",[\"mpa.merchant.security.\",[22,1,[]]],null]],null],false],[0,\" (\"],[1,[27,\"if\",[[22,2,[]],[22,2,[]],\"Not Available\"],null],false],[0,\")\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"risk-score-progress progress\"],[9],[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[28,[\"progress-bar progress-bar-striped \",[27,\"get-grade-score-color\",[[22,2,[]],[22,1,[]]],null]]]],[11,\"role\",\"progressbar\"],[12,\"style\",[22,0,[\"riskScoreProgress\"]]],[11,\"aria-valuemin\",\"0\"],[11,\"aria-valuemax\",\"100\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/risk-score-progress.hbs"
    }
  });

  _exports.default = _default;
});