define("zerorisk-frontend/templates/components/generic/mpa-mid-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dzrd4wTl",
    "block": "{\"symbols\":[\"@mpa_mid\"],\"statements\":[[7,\"span\"],[11,\"class\",\"m-r-5\"],[9],[0,\"\\n  \"],[7,\"span\"],[12,\"class\",[28,[\"fs-12 label label-info \",[27,\"if\",[[22,1,[\"entity\",\"contactInfo\",\"companyName\"]],\"no-border-right\"],null]]]],[9],[0,\"\\n    \"],[7,\"span\"],[9],[1,[22,1,[\"mid\"]],false],[0,\" \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[22,1,[\"entity\",\"contactInfo\",\"companyName\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"fs-12 label no-border-left\"],[9],[0,\"\\n      \"],[1,[22,1,[\"entity\",\"contactInfo\",\"companyName\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/generic/mpa-mid-label.hbs"
    }
  });

  _exports.default = _default;
});