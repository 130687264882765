define("zerorisk-frontend/utils/enums/entity-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EntityTypeEnum = void 0;
  var MERCHANT = 'MERCHANT';
  var SERVICE_PROVIDER = 'SERVICE_PROVIDER';
  var EntityTypeEnum = {
    MERCHANT: MERCHANT,
    SERVICE_PROVIDER: SERVICE_PROVIDER
  };
  _exports.EntityTypeEnum = EntityTypeEnum;
});