define("zerorisk-frontend/mixins/components/debounced-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /**
     * Number of milliseconds to wait.
     * @type {Number}
     */
    wait: 500,

    /**
     * Trigger the function on the leading instead of the trailing edge of the wait interval. Defaults to false.
     * @type {Boolean}
     */
    immediate: false,

    /**
     * Usually debounced properties are one way, if you plan to manually update val, this will keep val and value in sync
     * @type {Boolean}
     */
    sync: false,

    /**
     * Bound value to be debounced
     */
    val: null,

    /**
     * Raw value
     */
    value: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('value', this.get('val'));

      if (this.sync) {
        Ember.addObserver(this, 'val', this, this._sync);
      }
    },
    onValueChange: Ember.observer('value', function () {
      this._valuePid = Ember.run.debounce(this, this._setVal, this.wait, this.immediate);
    }),
    _sync: function _sync() {
      var _this$getProperties = this.getProperties('val', 'value'),
          val = _this$getProperties.val,
          value = _this$getProperties.value;

      if (!this.isDestroying && !this.isDestroyed && !Ember.isEqual(val, value)) {
        this.set('value', val);
      }
    },
    _setVal: function _setVal() {
      if (!this.isDestroying && !this.isDestroyed) {
        this.set('val', this.get('value'));
      }
    },

    /**
     * Cleanup by canceling any current debounce
     */
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      Ember.run.cancel(this._valuePid);

      if (this.sync) {
        Ember.removeObserver(this, 'val', this, this._sync);
      }
    }
  });

  _exports.default = _default;
});