define("zerorisk-frontend/services/page-title-list", ["exports", "ember-page-title/services/page-title-list", "zerorisk-frontend/config/environment"], function (_exports, _pageTitleList, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function capitalize(key) {
    return key.charAt(0).toUpperCase() + key.slice(1);
  }

  var defaults = {};
  ['separator', 'prepend', 'replace'].forEach(function (key) {
    if (_environment.default.pageTitle && _environment.default.pageTitle[key]) {
      defaults["default".concat(capitalize(key))] = _environment.default.pageTitle[key];
    }
  });

  var _default = _pageTitleList.default.extend(defaults);

  _exports.default = _default;
});