define("zerorisk-frontend/templates/components/mpa/hierarchy-pool", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "je9CMXC4",
    "block": "{\"symbols\":[\"child\",\"@deactivateLastPool\",\"@redirectToMerchants\",\"@parent\",\"@canGoBack\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row d-flex justify-content-center pool-item\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"d-flex justify-content-between align-items-center\"],[9],[0,\"\\n\"],[4,\"each\",[[22,4,[\"children\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"mpa/hierarchy-card\",null,[[\"mpa\",\"showSelect\",\"isSelected\",\"redirectToMerchants\",\"activatePool\"],[[22,1,[]],true,[27,\"eq\",[[23,[\"pool\",\"selectedMPA\"]],[22,1,[]]],null],[22,3,[]],[27,\"action\",[[22,0,[]],\"activatePool\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[22,5,[]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"btn btn-info btn-rounded m-r-5 btn-pool-back\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"app.back\"],null],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],[22,2,[]],[23,[\"pool\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/mpa/hierarchy-pool.hbs"
    }
  });

  _exports.default = _default;
});