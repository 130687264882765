define("zerorisk-frontend/mirage/serializers/saq", ["exports", "zerorisk-frontend/mirage/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    embed: false,
    serializeIds: 'always' // include: ['questions']
    // serialize(saq, request) {
    //   let json = BaseSerializer.prototype.serialize.apply(this, arguments);
    //   if (saq.questions) {
    //     json.result.saq.questions = saq.questions.models.map(sq => sq.id);
    //   }
    //   if (saq.answers) {
    //     json.result.saq.answers = saq.answers.models.map(sa => sa.id);
    //   }
    //   return json;
    // }

  });

  _exports.default = _default;
});