define("zerorisk-frontend/templates/components/text-wizard/single-answer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aZ0Ylhh6",
    "block": "{\"symbols\":[\"answer\",\"@answer\",\"@currentAnswer\"],\"statements\":[[7,\"div\"],[11,\"style\",\"width: 50%; text-align: center; margin: 0 auto;\"],[9],[0,\"\\n\"],[4,\"each\",[[22,3,[\"branches\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"btn btn-info bold btn-lg btn-xxl btn-rounded m-r-20\"],[11,\"style\",\"width: 100px;\"],[9],[0,\"\\n      \"],[1,[22,1,[\"text\"]],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],[22,2,[]],[22,1,[\"bringsTo\"]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/text-wizard/single-answer.hbs"
    }
  });

  _exports.default = _default;
});