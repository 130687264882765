define("zerorisk-frontend/templates/components/security/risk-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6h/IL20y",
    "block": "{\"symbols\":[\"@scoreAsLabel\",\"@score\",\"@description\"],\"statements\":[[7,\"p\"],[11,\"class\",\"p-text bold\"],[9],[1,[27,\"t\",[\"mpa.merchants.assessment.risk_rating.score_indicator\"],null],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"stock-price\"],[9],[0,\"\\n  \"],[7,\"span\"],[12,\"class\",[28,[\"p-tag \",[27,\"risk-score-label\",[[22,2,[]]],null],\" \",[27,\"unless\",[[22,1,[]],\"not-available\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[22,1,[]]],null,{\"statements\":[[0,\"      \"],[1,[22,1,[]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"mpa.merchants.assessment.risk_rating.scoring_info_not_available\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"p\"],[11,\"class\",\"p-text bold m-t-15\"],[9],[1,[27,\"t\",[\"app.description\"],null],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"stock-status\"],[9],[0,\"\\n  \"],[1,[22,3,[]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/security/risk-type.hbs"
    }
  });

  _exports.default = _default;
});