define("zerorisk-frontend/templates/components/modals/impersonate/login-as", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zjI8LFd6",
    "block": "{\"symbols\":[\"@loginAsMerchant\"],\"statements\":[[4,\"ember-remodal\",null,[[\"forService\",\"name\",\"onClose\",\"onOpen\"],[true,\"impersonate-login-as\",[27,\"action\",[[22,0,[]],\"resetToken\"],null],[27,\"action\",[[22,0,[]],[27,\"perform\",[[22,0,[\"loadTokenTask\"]]],null]],null]]],{\"statements\":[[0,\"  \"],[7,\"header\"],[11,\"class\",\"modal-header\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-user m-r-5\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n      Login As\\n\"],[4,\"if\",[[22,0,[\"actionDisabled\"]]],null,{\"statements\":[[0,\"        \"],[7,\"i\"],[11,\"class\",\"fa fa-spinner fa-spin\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"modal-body rounded-b-corners p-t-40 text-center\"],[9],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"Click the below Button to access the brand\"],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-sm btn-complete m-t-15\"],[12,\"disabled\",[22,0,[\"actionDisabled\"]]],[9],[0,\"\\n      MSP Portal\\n    \"],[3,\"action\",[[22,0,[]],\"redirectToPortal\"]],[10],[0,\"\\n\\n    \"],[7,\"p\"],[11,\"class\",\"m-t-40 m-b-20\"],[9],[0,\"\\n      Click here if you want to choice the Legacy Version of the MSP Portal\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-default\"],[12,\"disabled\",[22,0,[\"actionDisabled\"]]],[9],[0,\"\\n      Legacy MSP Portal\\n    \"],[3,\"action\",[[22,0,[]],[22,1,[]],[22,0,[\"token\"]]]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/modals/impersonate/login-as.hbs"
    }
  });

  _exports.default = _default;
});