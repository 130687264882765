define("zerorisk-frontend/utils/enums/message-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MessageTypeEnum = void 0;
  var COMMUNICATION = 'COMMUNICATION';
  var ASV_SCAN = 'ASV_SCAN';
  var MessageTypeEnum = {
    COMMUNICATION: COMMUNICATION,
    ASV_SCAN: ASV_SCAN
  };
  _exports.MessageTypeEnum = MessageTypeEnum;
});