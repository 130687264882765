define("zerorisk-frontend/templates/components/merchants/filters/steps/attribute-step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "unkZBW85",
    "block": "{\"symbols\":[\"options\",\"group\",\"option\"],\"statements\":[[4,\"if\",[[22,0,[\"hasOptions\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"input-section\"],[9],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"class\",\"placeholder\"],[\"text\",[22,0,[\"searchValue\"]],\"form-control bg-transparent\",[27,\"t\",[\"app.type_to_search\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"each\",[[27,\"-each-in\",[[22,0,[\"availableOptions\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"dropdown-header\"],[9],[0,\"\\n      \"],[1,[22,2,[]],false],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[4,\"each\",[[22,1,[]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"attribute-item m-b-5 m-t-5\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"fs-15\"],[9],[0,\"\\n          \"],[1,[27,\"enum-to-friendly\",[[22,3,[\"title\"]]],null],false],[0,\"\\n        \"],[10],[0,\"\\n\\n\"],[0,\"      \"],[3,\"action\",[[22,0,[]],\"optionSelected\",[22,3,[]]]],[10],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[1,2]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"app.no_results\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/merchants/filters/steps/attribute-step.hbs"
    }
  });

  _exports.default = _default;
});