define("zerorisk-frontend/templates/components/security/flagged-technologies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4GsSTEJb",
    "block": "{\"symbols\":[\"@flaggedTechnologies\"],\"statements\":[[7,\"div\"],[11,\"class\",\"card card-stock\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card-header d-flex p-0 justify-content-between align-items-center\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card-title p-mb-2 p-mr-2\"],[9],[0,\"\\n      \"],[7,\"h5\"],[9],[0,\"Frequently Vulnerable Technologies\"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card-body p-0 m-t-20\"],[9],[0,\"\\n    \"],[1,[27,\"mpa/merchants/manage/flagged-technologies\",null,[[\"flaggedTechnologies\"],[[22,1,[]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/security/flagged-technologies.hbs"
    }
  });

  _exports.default = _default;
});