define("zerorisk-frontend/utils/constants/invalidations", ["exports", "zerorisk-frontend/utils/types"], function (_exports, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.INVALIDATIONS = void 0;
  var INVALIDATIONS = [{
    reason: _types.InvalidationReasons.LOGOUT,
    message: "You've successfully logged out."
  }, {
    reason: _types.InvalidationReasons.INACTIVITY,
    message: 'You were automatically logged out due to inactivity.'
  }, {
    reason: _types.InvalidationReasons.NO_PRIVILEGE,
    message: 'Your account does not have access to ZeroRisk for Merchants. If you think this is a mistake, please contact support.'
  }, {
    reason: _types.InvalidationReasons.LOGIN_AS,
    message: 'Logging in as other user'
  }, {
    reason: _types.InvalidationReasons.LOGIN_AS_ENDED,
    message: 'Your session as of other user has expired'
  }, {
    reason: _types.InvalidationReasons.DEFAULT,
    message: 'Your session has expired. Please log back in.'
  }];
  _exports.INVALIDATIONS = INVALIDATIONS;
});