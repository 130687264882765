define("zerorisk-frontend/mixins/routes/pagination-mixin", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_routeMixin.default, {
    perPage: 10,
    page: 1,
    pageResetRequested: false,
    paramsMapping: {
      page: 'page',
      perPage: 'perPage',
      total_pages: 'totalPages'
    },
    actions: {
      queryParamsDidChange: function queryParamsDidChange(changed) {
        var _this$_super;

        var paramChanged = Object.keys(changed)[0];

        if (this.controller && paramChanged != 'page') {
          this.set('pageResetRequested', true);
        }

        return (// TODO: This call to super is within an action, and has to refer to the parent
          // class's actions to be safe. This should be refactored to call a normal method
          // on the parent class. If the parent class has not been converted to native
          // classes, it may need to be refactored as well. See
          // https: //github.com/scalvert/ember-es6-class-codemod/blob/master/README.md
          // for more details.
          (_this$_super = this._super).call.apply(_this$_super, [this].concat(Array.prototype.slice.call(arguments)))
        );
      }
    }
  });

  _exports.default = _default;
});