define("zerorisk-frontend/utils/assessment-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "id": 2,
    "entity": 2,
    "type": "ZERORISK_SCORE",
    "status": "FINISHED",
    "report": {
      "domain": "http://www.e-tote.com",
      "host": "e-tote.com",
      "externalId": "0894c0ad94ae4f9da56acbc427f452481599940751924",
      "assessmentType": "FULL",
      "assessmentStatus": "COMPLETED",
      "report": {
        "businessDetails": {
          "spending": 0,
          "isShoppingCartDiscovered": false,
          "trafficRanks": {
            "qrank": -1,
            "arank": -1,
            "majestic": 0,
            "umbrella": 0
          },
          "domainAge": {
            "found": true,
            "domainCreationDate": "2002-12-10",
            "domainAgeInDays": 6487,
            "domainAgeInMonths": 209,
            "domainAgeInYears": 17
          },
          "webPage": {
            "title": "301 Moved Permanently",
            "description": "",
            "keywords": ""
          },
          "saqs": ["SAQ_A"],
          "channels": ["E_COMMERCE"],
          "merchantLevel": null,
          "state": "",
          "country": "GB",
          "vertical": "Unknown",
          "city": "Bristol",
          "postcode": "BS14 0BN",
          "companyName": "Tote Limited"
        },
        "accuracy": {
          "evaluatedPortsOnIpsPercentage": 100.00,
          "evaluatedRiskOnIpsPercentage": 100.00,
          "isTechnologyAssessed": true,
          "isDataLeaksAssessed": true,
          "isMxRecordAssessed": true,
          "evaluatedRiskOnSubDomainsPercentage": 100.00
        },
        "surfaceComplexity": {
          "onlineSubdomains": 1,
          "offlineSubdomains": null,
          "discoveredIps": 1,
          "exclusiveIpsPercentage": 100.00,
          "discoveredPortsGrade": 2,
          "discoveredHostingProvidersGrade": 2
        },
        "technologyAssessments": [{
          "domain": "e-tote.com",
          "isFromSubpages": false,
          "discoveredHostingProviders": [{
            "name": "Amazon",
            "isPopulardHostingProvider": true,
            "isPcidHostingProvider": true
          }, {
            "name": "Amazon Ireland Region",
            "isPopulardHostingProvider": true,
            "isPcidHostingProvider": true
          }],
          "discoveredCMSs": null,
          "discoveredPaymentProperties": null,
          "discoveredFrameworks": null,
          "discoveredRiskyTechnologies": null
        }],
        "certificates": [{
          "domain": "e-tote.com",
          "openedPort": 443,
          "protocol": "tcp",
          "versions": ["-TLSv1", "-SSLv2", "-SSLv3", "-TLSv1.1", "TLSv1.2", "-TLSv1.3"],
          "expired": false,
          "certificateRiskGrade": 1,
          "issuer": "Let's Encrypt",
          "isIssuerTrusted": false,
          "blacklisted": false,
          "validPeer": true,
          "deprecatedIssuer": false,
          "nameMatch": true,
          "valid": true,
          "isTlsPortWithNoCertificate": false,
          "analysisSource": "a+s",
          "ipv4": "52.16.148.22"
        }],
        "mxRecord": {
          "isDmarcEnabled": false,
          "isDmarcEnforced": false,
          "isEmailConfigured": true,
          "isEmailSpoofable": false,
          "isFreeEmail": false
        },
        "rootDomainExposure": {
          "isDataLeaked": false,
          "isBlacklisted": false,
          "dataLeaks": null,
          "blacklists": null
        },
        "domainsSecurity": {
          "securityHeaders": [{
            "domain": "e-tote.com",
            "violations": [{
              "headerName": "strict-transport-security",
              "violationReason": "Missing security header.",
              "violationGrade": 3
            }, {
              "headerName": "x-content-type-options",
              "violationReason": "Missing security header.",
              "violationGrade": 3
            }, {
              "headerName": "referrer-policy",
              "violationReason": "Missing security header.",
              "violationGrade": 3
            }, {
              "headerName": "x-frame-options",
              "violationReason": "Missing security header.",
              "violationGrade": 3
            }, {
              "headerName": "content-security-policy",
              "violationReason": "Missing security header.",
              "violationGrade": 3
            }, {
              "headerName": "x-xss-protection",
              "violationReason": "Missing security header. This is deprecated by content-security-policy.",
              "violationGrade": 3
            }, {
              "headerName": "x-permitted-cross-domain-policies",
              "violationReason": "Missing security header.",
              "violationGrade": 3
            }, {
              "headerName": "feature-policy",
              "violationReason": "Missing security header.",
              "violationGrade": 3
            }, {
              "headerName": "expect-ct",
              "violationReason": "Missing security header.",
              "violationGrade": 1
            }],
            "code": 301,
            "status": "HTTP/1.1 301 Moved Permanently",
            "date": "Sun, 13 Sep 2020 07:05:10 GMT",
            "server": "nginx/1.16.1",
            "upgrade": null,
            "connection": "keep-alive",
            "last-modified": null,
            "etag": null,
            "accept-ranges": null,
            "content-length": "169",
            "vary": null,
            "content-type": "text/html",
            "strict-transport-security": null,
            "x-xss-protection": null,
            "x-content-type-options": null,
            "referrer-policy": null,
            "x-frame-options": null,
            "content-security-policy": null,
            "x-permitted-cross-domain-policies": null,
            "feature-policy": null,
            "expect-ct": null,
            "set-cookie": null
          }],
          "securityProperties": [{
            "domain": "e-tote.com",
            "trustScore": 100,
            "isSuspendedSite": false,
            "isMostAbusedTld": false,
            "isRobotsNoindex": false,
            "isEmptyPageTitle": false,
            "isDomainBlacklisted": false,
            "isSuspiciousDomain": false,
            "isSinkholedDomain": false,
            "isRiskyGeoLocation": false,
            "isChinaCountry": false,
            "isExternalRedirect": true,
            "isValidHttps": true,
            "isWebsitePopular": false,
            "isDomainRecent": "no",
            "isHeuristicPattern": false
          }]
        },
        "ipsSecurity": {
          "securityAssessments": [{
            "isFurtherInvestigationSuggested": true,
            "score": {
              "score": 31.4,
              "cve": 100,
              "attackSurface": 100,
              "encryption": 100,
              "rms": 0,
              "storage": 0,
              "web": 0,
              "torrents": 0
            },
            "ports": [{
              "openedPort": 80,
              "protocol": "tcp"
            }, {
              "openedPort": 443,
              "protocol": "tcp"
            }],
            "ipv4": "52.16.148.22",
            "cves": null
          }]
        },
        "summarizedFindings": {
          "spending": 0,
          "totalCost": 615.00000,
          "certificatesRiskGrade": 1,
          "certificatesRiskGradeCompletenessPercentage": 100.00,
          "domainsGlobalTrustScore": 100,
          "domainsGlobalTrustScoreCompletenessPercentage": 100.00,
          "ipsGlobalSecurityScore": 69,
          "ipsCvesAverageCvssScore": null,
          "globalTrustScore": 84,
          "globalTrustScoreCompletenessPercentage": 100.00,
          "paymentPropertiesTrustScore": null,
          "globalOverlayedTrustScore": 79,
          "paymentPropertiesOverlayedTrustScore": null,
          "ipsCvesTotalNumber": 0,
          "securityHeadersViolations": 9,
          "isNonPopularCmsFound": false
        },
        "plugins": {
          "magento": {
            "isSuccessfullyExecuted": true,
            "isMagentoVersion1": false,
            "isPotentialMagecart": false,
            "domain": null
          },
          "wooDiscountPrices": {
            "domain": null,
            "isSuccessfullyExecuted": false,
            "isWooDiscountPricesVulnerable": false,
            "isWooCommerce": false,
            "isWooDiscountPrices": false
          },
          "riskyTechnologies": {
            "isSuccessfullyExecuted": true,
            "riskyTechnologies": []
          },
          "tlsPortsWithoutCertificate": {
            "isSuccessfullyExecuted": true,
            "missingCertificates": []
          },
          "ecommerceWithoutCommonCart": {
            "isSuccessfullyExecuted": true,
            "isECommerceWithoutCommonCart": false,
            "isECommerceWithoutPaymentProperties": true
          },
          "ecommerceWithoutProperSaq": {
            "isSuccessfullyExecuted": true,
            "isCommonCartDiscovered": null,
            "saqs": ["SAQ_A"],
            "channels": ["E_COMMERCE"],
            "isWrongSaq": false
          }
        }
      },
      "created": "2020-09-12T19:59:11.924+00:00",
      "updated": "2020-09-29T15:47:04.233+00:00"
    },
    "target": "e-tote.com",
    "targetType": "URL",
    "created": "2018-07-01T00:00:00.000+00:00",
    "createdBy": "username"
  };
  _exports.default = _default;
});