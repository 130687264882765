define("zerorisk-frontend/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fbAJQVk+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"title\"],[9],[0,\"\\n  \"],[1,[22,0,[\"model\",\"title\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/head.hbs"
    }
  });

  _exports.default = _default;
});