define("zerorisk-frontend/templates/components/file-tools/upload-revision", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aCEFnWk3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"header\"],[11,\"class\",\"modal-header\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-download m-r-5\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\" \"],[1,[27,\"t\",[\"files.button_upload_revision\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"modal-body rounded-b-corners\"],[9],[0,\"\\n\"],[4,\"file-tools/uploader\",null,[[\"onfileadd\",\"class\"],[[27,\"action\",[[22,0,[]],\"onfileadd\"],null],\"p-t-25 p-b-25\"]],{\"statements\":[[0,\"    \"],[7,\"h3\"],[11,\"class\",\"text-center file-uploader-title\"],[9],[1,[27,\"t\",[\"files.button_upload_revision\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/file-tools/upload-revision.hbs"
    }
  });

  _exports.default = _default;
});