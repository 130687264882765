define("zerorisk-frontend/mirage/serializers/dashboard", ["exports", "zerorisk-frontend/mirage/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize: function serialize(dashboard, request) {
      var json = _application.default.prototype.serialize.apply(this, arguments);

      return json.result.dashboards[0];
    }
  });

  _exports.default = _default;
});