define("zerorisk-frontend/cldrs/lt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*jslint eqeq: true*/
  var _default = [{
    "locale": "lt",
    "pluralRuleFunction": function pluralRuleFunction(n, ord) {
      var s = String(n).split("."),
          f = s[1] || "",
          t0 = Number(s[0]) == n,
          n10 = t0 && s[0].slice(-1),
          n100 = t0 && s[0].slice(-2);
      if (ord) return "other";
      return n10 == 1 && (n100 < 11 || n100 > 19) ? "one" : n10 >= 2 && n10 <= 9 && (n100 < 11 || n100 > 19) ? "few" : f != 0 ? "many" : "other";
    },
    "fields": {
      "year": {
        "displayName": "metai",
        "relative": {
          "0": "šiais metais",
          "1": "kitais metais",
          "-1": "praėjusiais metais"
        },
        "relativeTime": {
          "future": {
            "one": "po {0} metų",
            "few": "po {0} metų",
            "many": "po {0} metų",
            "other": "po {0} metų"
          },
          "past": {
            "one": "prieš {0} metus",
            "few": "prieš {0} metus",
            "many": "prieš {0} metų",
            "other": "prieš {0} metų"
          }
        }
      },
      "year-short": {
        "displayName": "m.",
        "relative": {
          "0": "šiais metais",
          "1": "kitais metais",
          "-1": "praėjusiais metais"
        },
        "relativeTime": {
          "future": {
            "one": "po {0} m.",
            "few": "po {0} m.",
            "many": "po {0} m.",
            "other": "po {0} m."
          },
          "past": {
            "one": "prieš {0} m.",
            "few": "prieš {0} m.",
            "many": "prieš {0} m.",
            "other": "prieš {0} m."
          }
        }
      },
      "month": {
        "displayName": "mėnuo",
        "relative": {
          "0": "šį mėnesį",
          "1": "kitą mėnesį",
          "-1": "praėjusį mėnesį"
        },
        "relativeTime": {
          "future": {
            "one": "po {0} mėnesio",
            "few": "po {0} mėnesių",
            "many": "po {0} mėnesio",
            "other": "po {0} mėnesių"
          },
          "past": {
            "one": "prieš {0} mėnesį",
            "few": "prieš {0} mėnesius",
            "many": "prieš {0} mėnesio",
            "other": "prieš {0} mėnesių"
          }
        }
      },
      "month-short": {
        "displayName": "mėn.",
        "relative": {
          "0": "šį mėnesį",
          "1": "kitą mėnesį",
          "-1": "praėjusį mėnesį"
        },
        "relativeTime": {
          "future": {
            "one": "po {0} mėn.",
            "few": "po {0} mėn.",
            "many": "po {0} mėn.",
            "other": "po {0} mėn."
          },
          "past": {
            "one": "prieš {0} mėn.",
            "few": "prieš {0} mėn.",
            "many": "prieš {0} mėn.",
            "other": "prieš {0} mėn."
          }
        }
      },
      "day": {
        "displayName": "diena",
        "relative": {
          "0": "šiandien",
          "1": "rytoj",
          "2": "poryt",
          "-2": "užvakar",
          "-1": "vakar"
        },
        "relativeTime": {
          "future": {
            "one": "po {0} dienos",
            "few": "po {0} dienų",
            "many": "po {0} dienos",
            "other": "po {0} dienų"
          },
          "past": {
            "one": "prieš {0} dieną",
            "few": "prieš {0} dienas",
            "many": "prieš {0} dienos",
            "other": "prieš {0} dienų"
          }
        }
      },
      "day-short": {
        "displayName": "d.",
        "relative": {
          "0": "šiandien",
          "1": "rytoj",
          "2": "poryt",
          "-2": "užvakar",
          "-1": "vakar"
        },
        "relativeTime": {
          "future": {
            "one": "po {0} d.",
            "few": "po {0} d.",
            "many": "po {0} d.",
            "other": "po {0} d."
          },
          "past": {
            "one": "prieš {0} d.",
            "few": "prieš {0} d.",
            "many": "prieš {0} d.",
            "other": "prieš {0} d."
          }
        }
      },
      "hour": {
        "displayName": "valanda",
        "relative": {
          "0": "šią valandą"
        },
        "relativeTime": {
          "future": {
            "one": "po {0} valandos",
            "few": "po {0} valandų",
            "many": "po {0} valandos",
            "other": "po {0} valandų"
          },
          "past": {
            "one": "prieš {0} valandą",
            "few": "prieš {0} valandas",
            "many": "prieš {0} valandos",
            "other": "prieš {0} valandų"
          }
        }
      },
      "hour-short": {
        "displayName": "val.",
        "relative": {
          "0": "šią valandą"
        },
        "relativeTime": {
          "future": {
            "one": "po {0} val.",
            "few": "po {0} val.",
            "many": "po {0} val.",
            "other": "po {0} val."
          },
          "past": {
            "one": "prieš {0} val.",
            "few": "prieš {0} val.",
            "many": "prieš {0} val.",
            "other": "prieš {0} val."
          }
        }
      },
      "minute": {
        "displayName": "minutė",
        "relative": {
          "0": "šią minutę"
        },
        "relativeTime": {
          "future": {
            "one": "po {0} minutės",
            "few": "po {0} minučių",
            "many": "po {0} minutės",
            "other": "po {0} minučių"
          },
          "past": {
            "one": "prieš {0} minutę",
            "few": "prieš {0} minutes",
            "many": "prieš {0} minutės",
            "other": "prieš {0} minučių"
          }
        }
      },
      "minute-short": {
        "displayName": "min.",
        "relative": {
          "0": "šią minutę"
        },
        "relativeTime": {
          "future": {
            "one": "po {0} min.",
            "few": "po {0} min.",
            "many": "po {0} min.",
            "other": "po {0} min."
          },
          "past": {
            "one": "prieš {0} min.",
            "few": "prieš {0} min.",
            "many": "prieš {0} min.",
            "other": "prieš {0} min."
          }
        }
      },
      "second": {
        "displayName": "sekundė",
        "relative": {
          "0": "dabar"
        },
        "relativeTime": {
          "future": {
            "one": "po {0} sekundės",
            "few": "po {0} sekundžių",
            "many": "po {0} sekundės",
            "other": "po {0} sekundžių"
          },
          "past": {
            "one": "prieš {0} sekundę",
            "few": "prieš {0} sekundes",
            "many": "prieš {0} sekundės",
            "other": "prieš {0} sekundžių"
          }
        }
      },
      "second-short": {
        "displayName": "sek.",
        "relative": {
          "0": "dabar"
        },
        "relativeTime": {
          "future": {
            "one": "po {0} sek.",
            "few": "po {0} sek.",
            "many": "po {0} sek.",
            "other": "po {0} sek."
          },
          "past": {
            "one": "prieš {0} sek.",
            "few": "prieš {0} sek.",
            "many": "prieš {0} sek.",
            "other": "prieš {0} sek."
          }
        }
      }
    }
  }];
  _exports.default = _default;
});