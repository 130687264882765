define("zerorisk-frontend/templates/subscription/manage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CjV50lm4",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"containers/subscription/manage-subscription\",null,[[\"subscription\",\"entity\"],[[22,0,[\"subscription\"]],[22,0,[\"model\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/subscription/manage.hbs"
    }
  });

  _exports.default = _default;
});