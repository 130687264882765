define("zerorisk-frontend/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "adlaH4ut",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container p-t-30\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card card-default bg-danger-dark box-shadow-spread padding-20\"],[9],[0,\"\\n      \"],[7,\"h3\"],[11,\"class\",\"text-white bold\"],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"fa fa-ambulance m-r-5\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n        \"],[1,[27,\"t\",[\"error.whoops\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"h5\"],[11,\"class\",\"text-white bold\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"error.smth_went_wrong\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"row padding-10 p-r-20 d-flex ml-auto\"],[9],[0,\"\\n        \"],[7,\"a\"],[11,\"href\",\"javascript:window.location.reload();\"],[11,\"class\",\"m-l-5 btn btn-lg btn-danger btn-rounded bold\"],[9],[0,\"\\n          \"],[1,[27,\"t\",[\"app.reload\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/error.hbs"
    }
  });

  _exports.default = _default;
});