define("zerorisk-frontend/templates/files/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "agbJEbG/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"content files-repository-container\"],[9],[0,\"\\n  \"],[1,[27,\"pg-navbar\",null,[[\"symbol\"],[\"file\"]]],false],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"container p-t-20 p-b-20\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"container container-fixed-lg p-t-20 p-b-20 sm-padding-10\"],[11,\"style\",\"max-width: 1000px;\"],[9],[0,\"\\n      \"],[1,[27,\"file-tools/new-file-creator\",null,[[\"entities\",\"useDefaultType\",\"onUploadComplete\"],[[22,0,[\"model\",\"entities\"]],false,[27,\"action\",[[22,0,[]],\"onUploadComplete\"],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/files/new.hbs"
    }
  });

  _exports.default = _default;
});