define("zerorisk-frontend/utils/constants/levels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    LEVEL1: {
      friendly: 'Level 1'
    },
    LEVEL2: {
      friendly: 'Level 2'
    },
    LEVEL3: {
      friendly: 'Level 3'
    },
    LEVEL4: {
      friendly: 'Level 4'
    },
    UNDETERMINED: {
      friendly: 'Undetermined'
    }
  };
  _exports.default = _default;
});