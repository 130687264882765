define("zerorisk-frontend/templates/components/modals/create-flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OL70BCHg",
    "block": "{\"symbols\":[\"@entity\"],\"statements\":[[4,\"ember-remodal\",null,[[\"forService\",\"name\"],[true,\"create-flag\"]],{\"statements\":[[0,\"  \"],[7,\"header\"],[11,\"class\",\"modal-header\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-flag m-r-5\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\" \"],[1,[27,\"t\",[\"mpa.merchant.flags.set_alarm\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"modal-body rounded-b-corners p-t-40\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"p-b-25\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"fs-14 label label-info\"],[9],[0,\"\\n        TODO\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[1,[27,\"forms/merchant/management/create-flag\",null,[[\"entity\",\"onComplete\"],[[22,1,[]],[27,\"action\",[[22,0,[]],\"onCreateComplete\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/modals/create-flag.hbs"
    }
  });

  _exports.default = _default;
});