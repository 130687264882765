define("zerorisk-frontend/templates/components/merchants/filters/filter-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5rqfOSow",
    "block": "{\"symbols\":[\"@filter\",\"@removeFilter\"],\"statements\":[[7,\"div\"],[11,\"class\",\"merchant-filter filter-tag d-flex\"],[9],[0,\"\\n  \"],[1,[22,0,[\"value\"]],false],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"icon-remove m-l-5\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-remove\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],[22,2,[]],[22,1,[]]]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/merchants/filters/filter-tag.hbs"
    }
  });

  _exports.default = _default;
});