define("zerorisk-frontend/templates/compromises/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rb8pydnu",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"containers/compromise/compromise-index\",null,[[\"reloadModel\",\"compromises\"],[[27,\"route-action\",[\"reloadModel\"],null],[22,0,[\"model\",\"compromises\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/compromises/index.hbs"
    }
  });

  _exports.default = _default;
});