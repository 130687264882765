define("zerorisk-frontend/utils/test-hierarchy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var instance8 = Ember.Object.create({
    id: 9,
    contactInfo: Ember.Object.create({
      companyName: "MILK LTD",
      email: "milk@gmail.com",
      contactName: "Milk"
    }),
    children: []
  });
  var instance9 = Ember.Object.create({
    id: 10,
    contactInfo: Ember.Object.create({
      companyName: "GOOD FOOD LTD",
      email: "goodfood@gmail.com",
      contactName: "Good Food"
    }),
    children: []
  });
  var instance3 = Ember.Object.create({
    id: 4,
    contactInfo: Ember.Object.create({
      companyName: "Silpo LTD",
      email: "silpo@gmail.com",
      contactName: "Silpo"
    }),
    children: [instance8, instance9]
  });
  var instance4 = Ember.Object.create({
    id: 5,
    contactInfo: Ember.Object.create({
      companyName: "Shwartz Group",
      email: "Shwartz@gmail.com",
      contactName: "Shwartz"
    }),
    children: []
  });
  var instance5 = Ember.Object.create({
    id: 6,
    contactInfo: Ember.Object.create({
      companyName: "Ingenico Group",
      email: "ingenico@gmail.com",
      contactName: "Ingenico"
    }),
    children: []
  });
  var instance6 = Ember.Object.create({
    id: 7,
    contactInfo: Ember.Object.create({
      companyName: "METRO Group",
      email: "metro@gmail.com",
      contactName: "Metro"
    }),
    children: []
  });
  var instance7 = Ember.Object.create({
    id: 8,
    contactInfo: Ember.Object.create({
      companyName: "ASHAN Group",
      email: "ashan@gmail.com",
      contactName: "ashan"
    }),
    children: []
  });
  var instance1 = Ember.Object.create({
    id: 2,
    contactInfo: Ember.Object.create({
      companyName: "Zerorisk LTD",
      email: "zerorisk@gmail.com",
      contactName: "Zerorisk Admin"
    }),
    children: [instance3, instance4, instance5]
  });
  var instance2 = Ember.Object.create({
    id: 3,
    contactInfo: Ember.Object.create({
      companyName: "Spectrum LTD",
      email: "spectrum@gmail.com",
      contactName: "Spectrum 9"
    }),
    children: [instance6, instance7]
  });

  var _default = Ember.Object.create({
    id: 1,
    contactInfo: Ember.Object.create({
      companyName: "Secure LTD",
      email: "secure@gmail.com",
      contactName: "Secure Name"
    }),
    children: [instance1, instance2]
  });

  _exports.default = _default;
});