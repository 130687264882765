define("zerorisk-frontend/templates/components/modals/disable-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/3UBz2pr",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ember-remodal\",null,[[\"forService\",\"name\"],[true,\"disable-user\"]],{\"statements\":[[0,\"  \"],[7,\"header\"],[11,\"class\",\"modal-header\"],[9],[0,\"\\n      \"],[7,\"h1\"],[9],[1,[27,\"t\",[\"enrollment.user_details.disable\"],null],false],[0,\" \"],[7,\"span\"],[11,\"style\",\"font-weight: 400;\"],[9],[1,[22,0,[\"user\",\"email\"]],false],[10],[0,\"?\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"modal-body\"],[11,\"style\",\"padding: 18px;\"],[9],[0,\"\\n  \\t\"],[7,\"p\"],[9],[1,[27,\"t\",[\"enrollment.user_details.disabling_description\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"modal-footer\"],[9],[0,\"\\n  \\t\"],[7,\"button\"],[11,\"class\",\"btn green\"],[9],[1,[27,\"t\",[\"app.button_yes\"],null],false],[3,\"action\",[[22,0,[]],\"confirm\"]],[10],[0,\"\\n  \\t\"],[7,\"button\"],[11,\"class\",\"btn transparent no-depth no-border\"],[9],[1,[27,\"t\",[\"app.button_cancel\"],null],false],[3,\"action\",[[22,0,[]],\"closeModal\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/modals/disable-user.hbs"
    }
  });

  _exports.default = _default;
});