define("zerorisk-frontend/utils/constants/services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    APPLICATIONS_SOFTWARE: {
      id: 1,
      friendly: 'Applications / software',
      localeKey: 'enums.services.APPLICATIONS_SOFTWARE'
    },
    HARDWARE: {
      id: 2,
      friendly: 'Hardware',
      localeKey: 'enums.services.HARDWARE'
    },
    INFRASTRUCTURE_NETWORK: {
      id: 3,
      friendly: 'Infrastructure / Network',
      localeKey: 'enums.services.INFRASTRUCTURE_NETWORK'
    },
    PHYSICAL_LOCATION_SPACE: {
      id: 4,
      friendly: 'Physical Space (co-location)',
      localeKey: 'enums.services.PHYSICAL_LOCATION_SPACE'
    },
    STORAGE: {
      id: 5,
      friendly: 'Storage',
      localeKey: 'enums.services.STORAGE'
    },
    WEB: {
      id: 6,
      friendly: 'Web',
      localeKey: 'enums.services.WEB'
    },
    SECURITY_SERVICES: {
      id: 7,
      friendly: 'Security Serices',
      localeKey: 'enums.services.SECURITY_SERVICES'
    },
    SECURE_HOSTING_PROVIDER: {
      id: 8,
      friendly: '3-D Secure Hosting Provider',
      localeKey: 'enums.services.SECURE_HOSTING_PROVIDER'
    },
    SHARED_HOSTING_PROVIDER: {
      id: 9,
      friendly: 'Shared Hosting Provider',
      localeKey: 'enums.services.SHARED_HOSTING_PROVIDER'
    },
    OTHER_HOSTING: {
      id: 10,
      friendly: 'Other Hosting (specify)',
      localeKey: 'enums.services.OTHER_HOSTING'
    },
    ACCOUNT_MANAGEMENT: {
      id: 11,
      friendly: 'Account Management',
      localeKey: 'enums.services.ACCOUNT_MANAGEMENT'
    },
    BACK_OFFICE_SERVICES: {
      id: 12,
      friendly: 'Back-Office Services',
      localeKey: 'enums.services.BACK_OFFICE_SERVICES'
    },
    BILLING_MANAGEMENT: {
      id: 13,
      friendly: 'Billing Management',
      localeKey: 'enums.services.BILLING_MANAGEMENT'
    },
    CLEARING_SETTLEMENT: {
      id: 14,
      friendly: 'Clearing and Settlement',
      localeKey: 'enums.services.CLEARING_SETTLEMENT'
    },
    NETWORK_PROVIDER: {
      id: 15,
      friendly: 'Network Provider',
      localeKey: 'enums.services.NETWORK_PROVIDER'
    },
    OTHERS: {
      id: 16,
      friendly: 'Others (specify)',
      localeKey: 'enums.services.OTHERS'
    },
    SYSTEMS_SECURITY_SERVICES: {
      id: 17,
      friendly: 'Systems security services',
      localeKey: 'enums.services.SYSTEMS_SECURITY_SERVICES'
    },
    IT_SUPPORT: {
      id: 18,
      friendly: 'IT Support',
      localeKey: 'enums.services.IT_SUPPORT'
    },
    PHYSICAL_SECURITY: {
      id: 19,
      friendly: 'Physical security',
      localeKey: 'enums.services.PHYSICAL_SECURITY'
    },
    TERMINAL_MANAGEMENT_SYSTEM: {
      id: 20,
      friendly: 'Terminal Management System',
      localeKey: 'enums.services.TERMINAL_MANAGEMENT_SYSTEM'
    },
    OTHER_SERVICES: {
      id: 21,
      friendly: 'Other services (specify)',
      localeKey: 'enums.services.OTHER_SERVICES'
    },
    FRAUD_CHARGEBACK: {
      id: 22,
      friendly: 'Fraud and Chargeback',
      localeKey: 'enums.services.FRAUD_CHARGEBACK'
    },
    ISSUER_PROCESSING: {
      id: 23,
      friendly: 'Issuer Processing',
      localeKey: 'enums.services.ISSUER_PROCESSING'
    },
    LOYALTY_PROGRAMS: {
      id: 24,
      friendly: 'Loyalty Programs',
      localeKey: 'enums.services.LOYALTY_PROGRAMS'
    },
    MERCHANT_SERVICES: {
      id: 25,
      friendly: 'Merchant Services',
      localeKey: 'enums.services.MERCHANT_SERVICES'
    },
    POS_CARD_PRESENT: {
      id: 26,
      friendly: 'POS / card present',
      localeKey: 'enums.services.POS_CARD_PRESENT'
    },
    INTERNET_ECOMMERCE: {
      id: 27,
      friendly: 'Internet / e-commerce',
      localeKey: 'enums.services.INTERNET_ECOMMERCE'
    },
    MOTO_CALL_CENTER: {
      id: 28,
      friendly: 'MOTO / Call Center',
      localeKey: 'enums.services.MOTO_CALL_CENTER'
    },
    ATM: {
      id: 29,
      friendly: 'ATM',
      localeKey: 'enums.services.ATM'
    },
    OTHER_PROCESSING: {
      id: 30,
      friendly: 'Other processing (specify)',
      localeKey: 'enums.services.OTHER_PROCESSING'
    },
    PAYMENT_GATEWAY_SWITCH: {
      id: 31,
      friendly: 'Payment Gateway/Switch',
      localeKey: 'enums.services.PAYMENT_GATEWAY_SWITCH'
    },
    PREPAID_SERVICES: {
      id: 32,
      friendly: 'Prepaid Services',
      localeKey: 'enums.services.PREPAID_SERVICES'
    },
    RECORDS_MANAGEMENT: {
      id: 33,
      friendly: 'Records Management',
      localeKey: 'enums.services.RECORDS_MANAGEMENT'
    },
    TAX_GOVERNMENT_PAYMENTS: {
      id: 34,
      friendly: 'TAX/Government Payments',
      localeKey: 'enums.services.TAX_GOVERNMENT_PAYMENTS'
    }
  };
  _exports.default = _default;
});