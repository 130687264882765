define("zerorisk-frontend/templates/raqs/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HjJ6j9WM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row bg-white\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-sm-12\"],[9],[0,\"\\n        \"],[1,[27,\"questionnaire/raq/graph-view\",null,[[\"raq\"],[[22,0,[\"model\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/raqs/view.hbs"
    }
  });

  _exports.default = _default;
});