define("zerorisk-frontend/utils/enums/flag-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FlagActionEnum = void 0;
  var COMPLETE_WIZARD = 'COMPLETE_WIZARD';
  var CREATE_SAQ = 'CREATE_SAQ';
  var SUBMIT_SAQ = 'SUBMIT_SAQ';
  var CREATE_IP = 'CREATE_IP';
  var CREATE_SCAN = 'CREATE_SCAN';
  var SUBMIT_SCAN = 'SUBMIT_SCAN';
  var CREATED_NOTIFICATION = 'CREATED_NOTIFICATION';
  var FlagActionEnum = {
    COMPLETE_WIZARD: COMPLETE_WIZARD,
    CREATE_SAQ: CREATE_SAQ,
    SUBMIT_SAQ: SUBMIT_SAQ,
    CREATE_IP: CREATE_IP,
    CREATE_SCAN: CREATE_SCAN,
    SUBMIT_SCAN: SUBMIT_SCAN,
    CREATED_NOTIFICATION: CREATED_NOTIFICATION
  };
  _exports.FlagActionEnum = FlagActionEnum;
});