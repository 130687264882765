define("zerorisk-frontend/components/m-image", ["exports", "ember-m-image/components/m-image"], function (_exports, _mImage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _mImage.default;
    }
  });
});