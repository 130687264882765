define("zerorisk-frontend/templates/components/modals/reject-saq-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9CZc1Cwx",
    "block": "{\"symbols\":[\"@saq\",\"@onClose\",\"@onSend\"],\"statements\":[[4,\"ember-remodal\",null,[[\"forService\",\"name\"],[true,\"reject-saq-upload\"]],{\"statements\":[[0,\"  \"],[7,\"header\"],[11,\"class\",\"modal-header\"],[9],[0,\"\\n      \"],[7,\"h1\"],[9],[0,\"Reject SAQ upload\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"modal-body\"],[11,\"style\",\"height: 150px;\"],[9],[0,\"\\n    \"],[1,[27,\"textarea\",null,[[\"rows\",\"class\",\"placeholder\",\"value\"],[\"10\",\"form-control h-100\",\"Provide additional feedback to this SAQ upload rejection..\",[22,1,[\"rejectionFeedback\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"modal-footer m-r-10\"],[9],[0,\"\\n  \\t\"],[7,\"button\"],[11,\"class\",\"btn btn-rounded transparent no-depth no-border\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"app.cancel\"],null],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],[22,2,[]]]],[10],[0,\"\\n  \\t\"],[7,\"button\"],[11,\"class\",\"btn btn-danger btn-rounded\"],[9],[0,\"\\n      \"],[1,[27,\"if\",[[22,1,[\"rejectionFeedback\"]],\"Reject\",\"Reject without feedback\"],null],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],[22,3,[]]]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/modals/reject-saq-upload.hbs"
    }
  });

  _exports.default = _default;
});