define("zerorisk-frontend/mirage/factories/contact-info", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    companyName: function companyName() {
      return _emberCliMirage.faker.name.firstName();
    }
  });

  _exports.default = _default;
});