define("zerorisk-frontend/utils/constants/business-channels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    MOTO: {
      id: 1,
      friendly: 'MOTO (Mail and Telephone order)',
      localeKey: 'enums.business_channels.MOTO'
    },
    E_COMMERCE: {
      id: 2,
      friendly: 'e-Commerce',
      localeKey: 'enums.business_channels.E_COMMERCE'
    },
    F2F: {
      id: 3,
      friendly: 'F2F (Face to Face)',
      localeKey: 'enums.business_channels.F2F'
    },
    OTHER: {
      id: 4,
      friendly: 'Other',
      localeKey: 'enums.business_channels.OTHER'
    }
  };
  _exports.default = _default;
});