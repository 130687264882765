define("zerorisk-frontend/templates/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/wfDn7L0",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n  \"],[1,[27,\"pg-navbar\",null,[[\"symbol\"],[\"users\"]]],false],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row m-t-50\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-sm-12\"],[9],[0,\"\\n\"],[4,\"generic/pg-card\",null,[[\"title\",\"symbol\",\"classNames\",\"cardBodyClassNames\",\"withControls\"],[\"Users\",\"user\",\"box-shadow-spread\",\"p-b-0 p-l-0 p-r-0\",true]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[22,0,[\"users\",\"length\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"class\",\"card-header d-flex pt-0 pb-0 justify-content-end align-items-center\"],[9],[0,\"\\n              \"],[7,\"button\"],[11,\"class\",\"btn btn-complete btn-rounded\"],[11,\"type\",\"submit\"],[9],[0,\"\\n                \"],[1,[27,\"t\",[\"app.button_add_new\"],null],false],[0,\"\\n              \"],[3,\"action\",[[22,0,[]],\"openInviteUserModal\"]],[10],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[1,[27,\"mpa/merchants/manage/users-list\",null,[[\"users\",\"openInviteUserModal\",\"requestDelete\",\"deleteUserTask\"],[[22,0,[\"users\"]],[27,\"action\",[[22,0,[]],\"openInviteUserModal\"],null],[27,\"action\",[[22,0,[]],\"requestDelete\"],null],[22,0,[\"deleteUserTask\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"modals/invite-user\",null,[[\"closeModal\",\"onInviteComplete\",\"merchant\"],[[27,\"action\",[[22,0,[]],\"closeInviteUserModal\"],null],[27,\"action\",[[22,0,[]],\"onInviteComplete\"],null],[22,0,[\"model\",\"merchant\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/users.hbs"
    }
  });

  _exports.default = _default;
});