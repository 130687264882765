define("zerorisk-frontend/utils/constants/scan-statuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    '': {
      friendly: 'Pending',
      labelClass: 'label-primary'
    },
    APPROVED: {
      friendly: 'Approved',
      labelClass: 'label-success'
    },
    RUNNING: {
      friendly: 'Running',
      labelClass: 'label-info'
    },
    FINISHED: {
      friendly: 'Finished',
      labelClass: 'label-success'
    },
    QUEUED: {
      friendly: 'Queued',
      labelClass: 'label-primary'
    },
    CANCELED: {
      friendly: 'Canceled',
      labelClass: 'label-danger'
    },
    FAILED: {
      friendly: 'Failed',
      labelClass: 'label-danger'
    },
    PAUSED: {
      friendly: 'Paused',
      labelClass: 'label-warning'
    },
    PENDING_APPROVAL: {
      friendly: 'Pending Approval',
      labelClass: 'label-warning'
    },
    REJECTED: {
      friendly: 'Rejected',
      labelClass: 'label-danger'
    }
  };
  _exports.default = _default;
});