define("zerorisk-frontend/templates/components/false-positive/evidences/upload-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yenYKc2b",
    "block": "{\"symbols\":[\"@attachments\",\"@vuln\"],\"statements\":[[7,\"button\"],[12,\"id\",[27,\"concat\",[\"evidence-upload-for-vuln-\",[22,2,[\"id\"]]],null]],[11,\"class\",\"btn btn-rounded btn-lg btn-larger btn-info text-center w-100 file-upload-modal-open-button d-flex align-items-center justify-content-around\"],[9],[0,\"\\n\"],[4,\"bs-tooltip\",null,[[\"placement\",\"triggerEvents\",\"viewportSelector\"],[\"top\",\"hover\",[27,\"concat\",[\"#evidence-upload-for-vuln-\",[22,2,[\"id\"]]],null]]],{\"statements\":[[0,\"    \"],[1,[27,\"t\",[\"saq.editor.button_upload_evidence.title\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[27,\"t\",[\"saq.editor.button_upload_evidence.title\"],null],false],[0,\"\\n\"],[4,\"if\",[[22,1,[\"length\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"label label-success pull-right m-l-5\"],[9],[0,\"\\n      \"],[1,[22,1,[\"length\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[3,\"action\",[[22,0,[]],\"openEvidencesUploaderModal\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/false-positive/evidences/upload-button.hbs"
    }
  });

  _exports.default = _default;
});