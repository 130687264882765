define("zerorisk-frontend/mirage/factories/scan-ip", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    ipNumber: _emberCliMirage.faker.list.random("127.0.0.1", "127.0.0.2", "127.0.0.3"),
    domain: function domain(i) {
      return "host".concat(i + 1);
    },
    originAssetIp: function originAssetIp(i) {
      return "".concat(i + 1);
    }
  });

  _exports.default = _default;
});