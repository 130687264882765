define("zerorisk-frontend/mirage/config.asv", ["exports", "faker"], function (_exports, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    // ASSET-IPS
    this.get('/api/v2/merchant/asv/assetIps', function (schema) {
      var assetIps = schema.assetIps.all();
      assetIps.models.forEach(function (ip) {
        var fail = false; // ip.pciPassed = true;

        ip.vulnerabilities.models.map(function (vuln) {
          if (vuln.pciFlag) {
            fail = true;
          }
        });

        if (fail) {
          ip.pciPassed = false;
        }
      });
      return assetIps;
    });
    this.get('/api/v2/merchant/asv/assetIps/:id', function (schema, request) {
      var id = request.params.id;
      var assetIp = schema.assetIps.find(id);
      var fail = false;
      assetIp.vulnerabilities.models.map(function (vuln) {
        if (vuln.pciFlag) {
          fail = true;
        }
      });

      if (fail) {
        assetIp.pciPassed = false;
      }

      return assetIp;
    });
    this.post('/api/v2/merchant/asv/assetIps', function (schema) {
      var attr = this.normalizedRequestAttrs();
      var entity = schema.entities.find(attr.entity);
      attr.entity = entity;
      attr.pciPassed = null;

      if (!attr.ipNumber) {
        attr.ipNumber = _faker.default.internet.ip();
      }

      return schema.assetIps.create(attr);
    });
    this.del('/api/v2/merchant/asv/assetIps/:id');
    this.post('/api/v2/merchant/asv/assetIps/resolve', function () {
      return {
        result: {
          domain: "231.235.472.13"
        },
        success: true
      };
    }); // VULNERABILITIES

    this.get('/api/v2/merchant/asv/vulnerabilities/:id');
    this.get('/api/v2/merchant/asv/vulnerabilities'); // SCANS

    this.get('/api/v2/merchant/asv/scans');
    this.get('/api/v2/merchant/asv/scans/:id');
    this.post('/api/v2/merchant/asv/scans', function (schema, request) {
      var data = JSON.parse(request.requestBody);
      var newScanIps = data.scan.assetIps.map(function (assetIp) {
        var newScanIp = {
          id: assetIp,
          ipNumber: "127.0.0.1",
          domain: "host".concat(assetIp),
          originAssetIp: assetIp
        };
        return schema.scanIps.create(newScanIp);
      });
      var entity = schema.entities.find(data.scan.entity);
      data.scan.scanIps = newScanIps;
      data.scan.entity = entity;
      data.scan.status = 'QUEUED';
      return schema.scans.create(data.scan);
    });
    this.put('/api/v2/merchant/asv/scans/:id', function (schema, request) {
      var scan_id = request.params.id;
      var data = JSON.parse(request.requestBody);
      return schema.scans.find(scan_id).update(data);
    });
    this.del('/api/v2/merchant/asv/scans/:id', function (schema, request) {
      var scan_id = request.params.id;
      return schema.scans.find(scan_id).destroy();
    });
    this.post('/api/v2/shared/files');
    this.get('/api/v2/shared/files'); // this.get('/api/v2/merchant/saqs');
    // this.get('/api/v2/merchant/saqs/:id');
    // this.put('/api/v2/merchant/saqs/:id');
    // this.get('/api/v2/merchant/saqQuestions', 'saqQuestions', { coalesce: true });
    // this.put('/api/v2/merchant/saqs/:id');
    // this.get('/api/v2/merchant/saqAnswers', 'saqAnswers', { coalesce: true });
    // this.get('/api/v2/merchant/saqAnswers/:id', 'saqAnswer');
    // this.put('/api/v2/merchant/saqAnswers/:id', (schema, request) => {
    //   let payload = JSON.parse(request.requestBody);
    //   payload.saqAnswer.questionId = payload.saqAnswer.question;
    //   payload.saqAnswer.saqId = payload.saqAnswer.saq;
    //   delete payload.saqAnswer.question;
    //   delete payload.saqAnswer.saq;
    //   payload = payload.saqAnswer;
    //   schema.db.saqAnswers.update(request.params.id, payload);
    //   return schema.saqAnswers.find(request.params.id);
    // });
    // this.post('/api/v2/merchant/saqAnswers', (schema, request) => {
    //   let payload = JSON.parse(request.requestBody);
    //   payload.saqAnswer.questionId = payload.saqAnswer.question;
    //   payload.saqAnswer.saqId = payload.saqAnswer.saq;
    //   delete payload.saqAnswer.question;
    //   delete payload.saqAnswer.saq;
    //   payload = payload.saqAnswer;
    //   return schema.saqAnswers.create(payload);
    //   // return schema.saqAnswers.find(request.params.id);
    // });
    // PASSTHROUGH ALL OTHER REQUESTS

    this.passthrough();
  }
});