define("zerorisk-frontend/mirage/factories/saq-answer", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    //question(i) { return i+1 },
    //saq: 1,
    responseType: "YES"
  });

  _exports.default = _default;
});