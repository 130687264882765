define("zerorisk-frontend/utils/constants/device-statuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    IN_STOCK: {
      friendly: 'In Stock',
      localeKey: 'pinpoint.devices.statuses.IN_STOCK',
      description: 'Device is stored somewhere and ready to be installed',
      icon: 'archive',
      labelClass: 'label-info',
      bgClass: 'bg-info',
      pointColorClass: 'complete-light'
    },
    IN_TRANSIT: {
      friendly: 'In Transit',
      localeKey: 'pinpoint.devices.statuses.IN_TRANSIT',
      description: 'Device is currently in transit towards the target destination',
      icon: 'truck',
      labelClass: 'label-primary',
      bgClass: 'bg-primary',
      pointColorClass: 'master-lightest'
    },
    INSTALLED: {
      friendly: 'Installed',
      localeKey: 'pinpoint.devices.statuses.INSTALLED',
      description: 'Device is installed and operational',
      icon: 'sign-in',
      labelClass: 'label-success',
      bgClass: 'bg-success',
      pointColorClass: 'success'
    },
    IN_MAINTENANCE: {
      friendly: 'In Maintenance',
      localeKey: 'pinpoint.devices.statuses.IN_MAINTENANCE',
      description: 'Device is under repair or maintenance',
      icon: 'exclamation-triangle',
      labelClass: 'label-warning',
      bgClass: 'bg-warning',
      pointColorClass: 'warning-light'
    },
    DISMISSED: {
      friendly: 'Dismissed',
      localeKey: 'pinpoint.devices.statuses.DISMISSED',
      description: 'Device has been dismissed and is no longer serviceable',
      icon: 'trash',
      labelClass: 'label-primary',
      bgClass: 'bg-primary',
      pointColorClass: 'master-lightest'
    }
  };
  _exports.default = _default;
});