define("zerorisk-frontend/mirage/factories/dashboard", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    result: function result() {
      return {
        dashboard: {
          actions: [{
            name: "CREATE_SAQ",
            resourceType: "SAQ",
            resourceId: null
          }, {
            name: "SCAN_IP",
            resourceType: "IP",
            resourceId: [1, 2, 3, 4, 5, 6, 7]
          }],
          individual: {
            status: {
              scanValid: false,
              pciPassed: false,
              saqCreated: false,
              saqSubmitted: false,
              asvEnabled: false,
              merchantStatus: _emberCliMirage.faker.list.random('Compliant', 'NotCompliant', 'Enrolled', 'NotEnrolled')()
            },
            saqs: {
              pending: 0,
              submitted: 0,
              completed: 0
            },
            ips: {
              compliant: 0,
              scanned: 0,
              fail: 0,
              notScanned: 7,
              total: 7
            }
          },
          aggregated: {
            status: {
              scanValid: false,
              pciPassed: false,
              saqCreated: false,
              saqSubmitted: false,
              asvEnabled: null,
              merchantStatus: _emberCliMirage.faker.list.random('Compliant', 'NotCompliant', 'Enrolled', 'NotEnrolled', 'Closed')()
            },
            saqs: {
              pending: 4,
              submitted: 0,
              completed: 0
            },
            ips: {
              compliant: 0,
              scanned: 0,
              fail: 0,
              notScanned: 9,
              total: 9
            }
          },
          merchants: {
            compliant: 1,
            total: 6
          }
        }
      };
    }
  });

  _exports.default = _default;
});