define("zerorisk-frontend/mirage/factories/asset-ip", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    ipNumber: function ipNumber(i) {
      return _emberCliMirage.faker.internet.ip();
    },
    domain: function domain(i) {
      return _emberCliMirage.faker.internet.domainName();
    },
    description: function description(i) {
      return _emberCliMirage.faker.random.words();
    },
    tag: function tag(i) {
      return _emberCliMirage.faker.random.word();
    },
    enabled: true,
    lastScanDate: null,
    lastScan: null,
    quarterly: false // pciPassed: faker.list.random(true, false)

  });

  _exports.default = _default;
});