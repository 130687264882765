define("zerorisk-frontend/templates/components/pg-navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JT29FL2b",
    "block": "{\"symbols\":[\"@symbol\",\"&default\"],\"statements\":[[1,[27,\"subscription-alert\",null,[[\"class\"],[\"m-l-25 m-t-20 m-r-25 m-b-5\"]]],false],[0,\"\\n\\n\"],[7,\"div\"],[12,\"class\",[28,[\"jumbotron navbar m-b-0 \",[22,0,[\"navbarClasses\"]]]]],[11,\"style\",\"overflow: visible;\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"d-flex justify-content-between p-l-30 p-r-30 p-t-10 p-b-5\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"pull-left\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"inner d-flex align-items-center\"],[9],[0,\"\\n\"],[4,\"if\",[[22,1,[]]],null,{\"statements\":[[0,\"          \"],[7,\"i\"],[12,\"class\",[28,[\"fa fa-\",[22,1,[]],\" m-r-5 breadcrumb-icon\"]]],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[1,[27,\"bread-crumbs\",null,[[\"tagName\",\"linkable\",\"crumbClass\"],[\"ol\",true,\"breadcrumb-item\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[14,2],[0,\"\\n\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/pg-navbar.hbs"
    }
  });

  _exports.default = _default;
});