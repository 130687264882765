define("zerorisk-frontend/mirage/serializers/application", ["exports", "ember-cli-mirage/serializers/rest-serializer", "ember-cli-mirage/utils/inflector"], function (_exports, _restSerializer, _inflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _restSerializer.default.extend({
    // keyForAttribute(attr) {
    //   let _attr = RestSerializer.prototype.keyForAttribute.apply(this, arguments);
    //   return _attr.replace('Id', '');
    // },
    // keyForRelationship(attr) {
    //   debugger;
    //   let _attr = RestSerializer.prototype.keyForAttribute.apply(this, arguments);
    //   return _attr.replace('Id', '');
    // },
    // keyForRelationship(type) {
    //   debugger;
    // },
    // keyForEmbeddedRelationship(attributeName) {
    //   debugger;
    // },
    // keyForRelationshipIds(type) {
    //   debugger;
    // },
    // embed: true,
    serialize: function serialize(object, request) {
      // This is how to call super, as Mirage borrows [Backbone's implementation of extend](http://backbonejs.org/#Model-extend)
      var json = _restSerializer.default.prototype.serialize.apply(this, arguments);

      var result = {
        result: json,
        success: true
      };
      return result;
    }
  });

  _exports.default = _default;
});