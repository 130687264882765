define("zerorisk-frontend/templates/components/merchants/filters/steps/operator-step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Nk5Ink4W",
    "block": "{\"symbols\":[\"operator\"],\"statements\":[[4,\"if\",[[22,0,[\"hasOptions\"]]],null,{\"statements\":[[4,\"each\",[[22,0,[\"operators\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"step-option-item\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"fs-13 bold\"],[9],[0,\"\\n\"],[0,\"        \"],[1,[22,1,[]],false],[0,\"\\n      \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"option\",\"description\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"hint-text\"],[9],[0,\"\\n          \"],[1,[27,\"t\",[[23,[\"option\",\"description\"]]],null],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[3,\"action\",[[22,0,[]],\"optionSelected\",[22,1,[]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"app.no_results\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/merchants/filters/steps/operator-step.hbs"
    }
  });

  _exports.default = _default;
});