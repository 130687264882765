define("zerorisk-frontend/utils/constants/frequencies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    ONCE: {
      friendly: 'Once',
      shorten: true
    },
    DAILY: {
      friendly: 'Daily',
      shorten: true
    },
    WEEKLY: {
      friendly: 'Weekly',
      shorten: true
    },
    EVERY_TWO_WEEKS: {
      friendly: 'Every 2 weeks'
    },
    EVERY_THREE_WEEKS: {
      friendly: 'Every 3 weeks'
    },
    MONTHLY: {
      friendly: 'Monthly'
    },
    QUARTERLY: {
      friendly: 'Quarterly'
    },
    EVERY_6_MONTHS: {
      friendly: 'Every 6 months'
    },
    YEARLY: {
      friendly: 'Yearly'
    }
  };
  _exports.default = _default;
});