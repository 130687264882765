define("zerorisk-frontend/cldrs/sk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*jslint eqeq: true*/
  var _default = [{
    "locale": "sk",
    "pluralRuleFunction": function pluralRuleFunction(n, ord) {
      var s = String(n).split("."),
          i = s[0],
          v0 = !s[1];
      if (ord) return "other";
      return n == 1 && v0 ? "one" : i >= 2 && i <= 4 && v0 ? "few" : !v0 ? "many" : "other";
    },
    "fields": {
      "year": {
        "displayName": "rok",
        "relative": {
          "0": "tento rok",
          "1": "budúci rok",
          "-1": "minulý rok"
        },
        "relativeTime": {
          "future": {
            "one": "o {0} rok",
            "few": "o {0} roky",
            "many": "o {0} roka",
            "other": "o {0} rokov"
          },
          "past": {
            "one": "pred {0} rokom",
            "few": "pred {0} rokmi",
            "many": "pred {0} roka",
            "other": "pred {0} rokmi"
          }
        }
      },
      "year-short": {
        "displayName": "r.",
        "relative": {
          "0": "tento rok",
          "1": "budúci rok",
          "-1": "minulý rok"
        },
        "relativeTime": {
          "future": {
            "one": "o {0} r.",
            "few": "o {0} r.",
            "many": "o {0} r.",
            "other": "o {0} r."
          },
          "past": {
            "one": "pred {0} r.",
            "few": "pred {0} r.",
            "many": "pred {0} r.",
            "other": "pred {0} r."
          }
        }
      },
      "month": {
        "displayName": "mesiac",
        "relative": {
          "0": "tento mesiac",
          "1": "budúci mesiac",
          "-1": "minulý mesiac"
        },
        "relativeTime": {
          "future": {
            "one": "o {0} mesiac",
            "few": "o {0} mesiace",
            "many": "o {0} mesiaca",
            "other": "o {0} mesiacov"
          },
          "past": {
            "one": "pred {0} mesiacom",
            "few": "pred {0} mesiacmi",
            "many": "pred {0} mesiaca",
            "other": "pred {0} mesiacmi"
          }
        }
      },
      "month-short": {
        "displayName": "mes.",
        "relative": {
          "0": "tento mes.",
          "1": "budúci mes.",
          "-1": "minulý mes."
        },
        "relativeTime": {
          "future": {
            "one": "o {0} mes.",
            "few": "o {0} mes.",
            "many": "o {0} mes.",
            "other": "o {0} mes."
          },
          "past": {
            "one": "pred {0} mes.",
            "few": "pred {0} mes.",
            "many": "pred {0} mes.",
            "other": "pred {0} mes."
          }
        }
      },
      "day": {
        "displayName": "deň",
        "relative": {
          "0": "dnes",
          "1": "zajtra",
          "2": "pozajtra",
          "-2": "predvčerom",
          "-1": "včera"
        },
        "relativeTime": {
          "future": {
            "one": "o {0} deň",
            "few": "o {0} dni",
            "many": "o {0} dňa",
            "other": "o {0} dní"
          },
          "past": {
            "one": "pred {0} dňom",
            "few": "pred {0} dňami",
            "many": "pred {0} dňa",
            "other": "pred {0} dňami"
          }
        }
      },
      "day-short": {
        "displayName": "d.",
        "relative": {
          "0": "dnes",
          "1": "zajtra",
          "2": "pozajtra",
          "-2": "predvčerom",
          "-1": "včera"
        },
        "relativeTime": {
          "future": {
            "one": "o {0} d.",
            "few": "o {0} d.",
            "many": "o {0} d.",
            "other": "o {0} d."
          },
          "past": {
            "one": "pred {0} d.",
            "few": "pred {0} d.",
            "many": "pred {0} d.",
            "other": "pred {0} d."
          }
        }
      },
      "hour": {
        "displayName": "hodina",
        "relative": {
          "0": "v tejto hodine"
        },
        "relativeTime": {
          "future": {
            "one": "o {0} hodinu",
            "few": "o {0} hodiny",
            "many": "o {0} hodiny",
            "other": "o {0} hodín"
          },
          "past": {
            "one": "pred {0} hodinou",
            "few": "pred {0} hodinami",
            "many": "pred {0} hodinou",
            "other": "pred {0} hodinami"
          }
        }
      },
      "hour-short": {
        "displayName": "h",
        "relative": {
          "0": "v tejto hodine"
        },
        "relativeTime": {
          "future": {
            "one": "o {0} h",
            "few": "o {0} h",
            "many": "o {0} h",
            "other": "o {0} h"
          },
          "past": {
            "one": "pred {0} h",
            "few": "pred {0} h",
            "many": "pred {0} h",
            "other": "pred {0} h"
          }
        }
      },
      "minute": {
        "displayName": "minúta",
        "relative": {
          "0": "v tejto minúte"
        },
        "relativeTime": {
          "future": {
            "one": "o {0} minútu",
            "few": "o {0} minúty",
            "many": "o {0} minúty",
            "other": "o {0} minút"
          },
          "past": {
            "one": "pred {0} minútou",
            "few": "pred {0} minútami",
            "many": "pred {0} minúty",
            "other": "pred {0} minútami"
          }
        }
      },
      "minute-short": {
        "displayName": "min",
        "relative": {
          "0": "v tejto minúte"
        },
        "relativeTime": {
          "future": {
            "one": "o {0} min",
            "few": "o {0} min",
            "many": "o {0} min",
            "other": "o {0} min"
          },
          "past": {
            "one": "pred {0} min",
            "few": "pred {0} min",
            "many": "pred {0} min",
            "other": "pred {0} min"
          }
        }
      },
      "second": {
        "displayName": "sekunda",
        "relative": {
          "0": "teraz"
        },
        "relativeTime": {
          "future": {
            "one": "o {0} sekundu",
            "few": "o {0} sekundy",
            "many": "o {0} sekundy",
            "other": "o {0} sekúnd"
          },
          "past": {
            "one": "pred {0} sekundou",
            "few": "pred {0} sekundami",
            "many": "pred {0} sekundy",
            "other": "pred {0} sekundami"
          }
        }
      },
      "second-short": {
        "displayName": "s",
        "relative": {
          "0": "teraz"
        },
        "relativeTime": {
          "future": {
            "one": "o {0} s",
            "few": "o {0} s",
            "many": "o {0} s",
            "other": "o {0} s"
          },
          "past": {
            "one": "pred {0} s",
            "few": "pred {0} s",
            "many": "pred {0} s",
            "other": "pred {0} s"
          }
        }
      }
    }
  }];
  _exports.default = _default;
});