define("zerorisk-frontend/utils/validators/intl/length", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lengthValidator = void 0;

  // TODO: Testing approach, then implement other validators in similar way (c) Sviat
  var lengthValidator = function lengthValidator(evtType, context) {
    return (0, _emberCpValidations.validator)(evtType, {
      min: context.min,
      max: context.max,
      dependentKeys: ['model.intl.locale'],
      message: function message(type, value) {
        return this.model.intl.locale && this.createErrorMessage(type, value, context);
      }
    });
  };

  _exports.lengthValidator = lengthValidator;
});