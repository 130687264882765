define("zerorisk-frontend/templates/raqs/complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v+KDCDc2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row bg-white\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-md-12\"],[9],[0,\"\\n      \"],[1,[27,\"questionnaire/raq/graph-wizard\",null,[[\"raq\",\"requestSubmitRaq\",\"onWizardComplete\"],[[22,0,[\"model\"]],[27,\"action\",[[22,0,[]],\"requestSubmitRaq\"],null],[27,\"action\",[[22,0,[]],\"onWizardComplete\"],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/raqs/complete.hbs"
    }
  });

  _exports.default = _default;
});