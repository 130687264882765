define("zerorisk-frontend/utils/license-plan-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LICENSE_PLANS_MAP = {
    PREMIUM_5: function PREMIUM_5(mpaName) {
      return function (months) {
        return "PREMIUM_".concat(mpaName, "_").concat(months, "_5");
      };
    },
    PREMIUM_10: function PREMIUM_10(mpaName) {
      return function (months) {
        return "PREMIUM_".concat(mpaName, "_").concat(months, "_10");
      };
    },
    PREMIUM_15: function PREMIUM_15(mpaName) {
      return function (months) {
        return "PREMIUM_".concat(mpaName, "_").concat(months, "_15");
      };
    },
    PREMIUM_20: function PREMIUM_20(mpaName) {
      return function (months) {
        return "PREMIUM_".concat(mpaName, "_").concat(months, "_20");
      };
    },
    PREMIUM_25: function PREMIUM_25(mpaName) {
      return function (months) {
        return "PREMIUM_".concat(mpaName, "_").concat(months, "_25");
      };
    },
    BASE: function BASE(mpaName) {
      return function (months) {
        return "BASE_".concat(mpaName, "_").concat(months);
      };
    },
    FULL: function FULL(mpaName) {
      return function (months) {
        return "FULL_".concat(mpaName, "_").concat(months);
      };
    }
  };
  var _default = LICENSE_PLANS_MAP;
  _exports.default = _default;
});