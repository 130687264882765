define("zerorisk-frontend/utils/constants/flag-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    COMPLETE_WIZARD: {
      friendly: 'COMPLETE WIZARD'
    },
    CREATE_SAQ: {
      friendly: 'CREATE SAQ'
    },
    SUBMIT_SAQ: {
      friendly: 'SUBMIT SAQ'
    },
    CREATE_IP: {
      friendly: 'CREATE IP'
    },
    CREATE_SCAN: {
      friendly: 'CREATE SCAN'
    },
    SUBMIT_SCAN: {
      friendly: 'SUBMIT SCAN'
    },
    CREATED_NOTIFICATION: {
      friendly: 'CREATED NOTIFICATION'
    }
  };
  _exports.default = _default;
});