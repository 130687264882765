define("zerorisk-frontend/utils/constants/devices-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    STANDARD: {
      id: 1,
      friendly: 'Standard',
      localeKey: 'enums.device_types.STANDARD'
    },
    UNATTENDED: {
      id: 2,
      friendly: 'Unattended',
      localeKey: 'enums.device_types.UNATTENDED'
    },
    IOT: {
      id: 3,
      friendly: 'IOT',
      localeKey: 'enums.device_types.IOT'
    },
    CONTACTLESS_ONLY: {
      id: 4,
      friendly: 'Contact Less',
      localeKey: 'enums.device_types.CONTACTLESS_ONLY'
    }
  };
  _exports.default = _default;
});