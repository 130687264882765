define("zerorisk-frontend/utils/m-image-ajax-loader", ["exports", "ember-m-image/utils/m-image-ajax-loader"], function (_exports, _mImageAjaxLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _mImageAjaxLoader.default;
    }
  });
});