define("zerorisk-frontend/utils/enums/payment-gateway", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PaymentGatewayTypeEnum = void 0;
  var STRIPE = 'STRIPE';
  var INGENICO = 'INGENICO';
  var OFFLINE = 'OFFLINE';
  var PaymentGatewayTypeEnum = {
    STRIPE: STRIPE,
    INGENICO: INGENICO,
    OFFLINE: OFFLINE
  };
  _exports.PaymentGatewayTypeEnum = PaymentGatewayTypeEnum;
});