define("zerorisk-frontend/templates/components/kb-board/column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6xtnUpAO",
    "block": "{\"symbols\":[\"@icon\",\"@title\",\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"card-list\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card-list-header\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card-list-header-title pl-1\"],[9],[0,\"\\n      \"],[7,\"i\"],[12,\"class\",[28,[\"fa fa-\",[22,1,[]],\" mr-1 hint-text\"]]],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n      \"],[1,[22,2,[]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card-list-body\"],[11,\"tabindex\",\"0\"],[9],[0,\"\\n    \"],[14,3],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/kb-board/column.hbs"
    }
  });

  _exports.default = _default;
});