define("zerorisk-frontend/templates/components/assessments/report/risk-rating/score-and-reccomendation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SnHZL7eh",
    "block": "{\"symbols\":[\"@gradeModel\"],\"statements\":[[7,\"div\"],[11,\"class\",\"col-sm-12 col-md-2\"],[9],[0,\"\\n  \"],[7,\"p\"],[9],[1,[22,1,[\"key\"]],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"col-sm-12 col-md-2 risk-label\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row m-l-5\"],[9],[0,\"\\n    \"],[7,\"span\"],[12,\"class\",[28,[\"score-label p-tag \",[27,\"risk-score-label\",[[22,1,[\"score\"]]],null]]]],[9],[0,\"\\n      \"],[1,[27,\"risk-level-label\",[[22,1,[\"score\"]]],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"col-sm-12 col-md-6\"],[9],[0,\"\\n  \"],[7,\"p\"],[9],[1,[22,1,[\"reccomendation\"]],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"col-sm-12 col-md-2 risk-label\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row m-l-5\"],[9],[0,\"\\n    \"],[7,\"span\"],[12,\"class\",[28,[\"p-tag \",[27,\"if\",[[22,1,[\"pciFail\"]],\"red\",\"green\"],null]]]],[9],[0,\"\\n      \"],[1,[27,\"if\",[[22,1,[\"pciFail\"]],\"Yes\",\"No\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/assessments/report/risk-rating/score-and-reccomendation.hbs"
    }
  });

  _exports.default = _default;
});