define("zerorisk-frontend/mirage/serializers/scan", ["exports", "zerorisk-frontend/mirage/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serializeIds: 'always' // serialize(scan, request) {
    //   function serializeData(data){
    //     let before = JSON.stringify(data);
    //     return JSON.parse(before);
    //   } 
    //   let result = serializeData(scan);
    //   if (request.params.id){
    //     let fixedscanIps = serializeData(scan.scanIps.models);
    //     result.scanIps = fixedscanIps;
    //     return {
    //       "result": { "scan": result },
    //       "success": true
    //     };
    //   } else {
    //     let fixedScanIps;
    //     scan.models.forEach( scan => {
    //       fixedScanIps = serializeData(scan.scanIps.models);
    //       scan = serializeData(scan);
    //       scan.scanIps = fixedScanIps;
    //       result.models[scan.id - 1] = scan;
    //     });
    //     return {
    //       "result": { "scans": result.models },
    //       "success": true
    //     };
    //   }  
    // }

  });

  _exports.default = _default;
});