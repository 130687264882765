define("zerorisk-frontend/helpers/risks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.risks = risks;
  _exports.default = void 0;

  function risks(params
  /*, hash*/
  ) {
    var value = params[0],
        key = params[1]; // debugger;

    if (key == 'labelClass') {
      if (value >= 0 && value < 4) {
        return 'label-success';
      }

      if (value >= 4 && value < 8) {
        return 'label-default';
      }

      if (value >= 8 && value <= 10) {
        return 'label-danger';
      }
    }
  }

  var _default = Ember.Helper.helper(risks);

  _exports.default = _default;
});