define("zerorisk-frontend/templates/components/modals/invitation/link-generator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uBGH1yqw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ember-remodal\",null,[[\"forService\",\"name\",\"onClose\",\"onOpen\"],[true,\"invitation-link-generator\",[27,\"pipe-action\",[[27,\"action\",[[22,0,[]],\"reset\"],null]],null],[27,\"action\",[[22,0,[]],\"generateLink\"],null]]],{\"statements\":[[7,\"header\"],[11,\"class\",\"modal-header\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-link m-r-5\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n    Invitation Link\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"modal-body rounded-b-corners\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"or\",[[27,\"not\",[[22,0,[\"invitationLink\"]]],null],[23,[\"fetchInvitationLinkTask\",\"isRunning\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"p-t-25 p-b-25\"],[9],[0,\"\\n        \"],[1,[27,\"loading-spinner\",null,[[\"containerClass\"],[\"bg-transparent\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"p\"],[11,\"class\",\"p-t-10 p-b-25 d-flex align-items-center\"],[9],[0,\"\\n        \"],[1,[22,0,[\"invitationLink\"]],false],[0,\"\\n        \"],[5,\"copy-button\",[[11,\"title\",\"copy to clipboard\"]],[[\"@clipboardText\",\"@success\",\"@error\"],[[22,0,[\"invitationLink\"]],[27,\"action\",[[22,0,[]],\"onSuccess\"],null],[27,\"action\",[[22,0,[]],\"onError\"],null]]],{\"statements\":[[0,\"\\n            \"],[7,\"i\"],[11,\"class\",\"fa fa-clipboard\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/modals/invitation/link-generator.hbs"
    }
  });

  _exports.default = _default;
});