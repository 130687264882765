define("zerorisk-frontend/utils/date-utils", ["exports", "ember-power-calendar-moment/utils/date-utils"], function (_exports, _dateUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _dateUtils.default;
    }
  });
});