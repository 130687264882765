define("zerorisk-frontend/mixins/component-pagination", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_routeMixin.default, {
    store: Ember.inject.service()
  });

  _exports.default = _default;
});