define("zerorisk-frontend/templates/components/saq-editor/question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sZfYm3VG",
    "block": "{\"symbols\":[\"@question\"],\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"saq-editor-question-container container align-contents-center height-50\"],[11,\"style\",\"overflow: auto;\"],[9],[0,\"\\n\"],[0,\"        \"],[7,\"div\"],[11,\"class\",\"row justify-content-center align-items-center d-flex flex-column h-100\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"col-sm-10\"],[11,\"style\",\"min-height: 1px;\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"hint-text all-caps fs-11 font-montserrat\"],[9],[1,[27,\"t\",[\"saq.editor.question_title\"],null],false],[10],[0,\"\\n            \"],[7,\"h6\"],[11,\"class\",\"normal hint-text\"],[9],[1,[22,1,[\"preface\"]],true],[10],[0,\"\\n            \"],[7,\"h5\"],[11,\"class\",\"normal\"],[9],[1,[22,1,[\"text\"]],true],[10],[0,\"\\n\\n\"],[4,\"if\",[[22,1,[\"info\"]]],null,{\"statements\":[[0,\"              \"],[7,\"div\"],[11,\"class\",\"m-t-30 hint-text all-caps fs-10 font-montserrat\"],[9],[1,[27,\"t\",[\"saq.editor.question_explained\"],null],false],[10],[0,\"\\n              \"],[7,\"h6\"],[11,\"class\",\"saq-editor-question-info\"],[9],[0,\" \"],[1,[22,1,[\"info\"]],true],[0,\" \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[22,1,[\"testing\"]]],null,{\"statements\":[[0,\"              \"],[7,\"div\"],[11,\"class\",\"m-t-30 hint-text all-caps fs-10 font-montserrat\"],[9],[1,[27,\"t\",[\"saq.editor.how_to_answer\"],null],false],[10],[0,\"\\n              \"],[7,\"h6\"],[11,\"class\",\"saq-editor-question-testing normal\"],[9],[0,\" \"],[1,[22,1,[\"testing\"]],true],[0,\" \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"],[0,\"  \"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/saq-editor/question.hbs"
    }
  });

  _exports.default = _default;
});