define("zerorisk-frontend/utils/constants/role-types", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.RoleTypes = void 0;

  var _RoleTypes$ROLE_PINPO;

  var RoleTypes = {
    ROLE_PINPOINT_MANAGER_ZONE: 'ROLE_PINPOINT_MANAGER_ZONE',
    ROLE_PINPOINT_MANAGER_AREA: 'ROLE_PINPOINT_MANAGER_AREA',
    ROLE_PINPOINT_MANAGER_LOCATION: 'ROLE_PINPOINT_MANAGER_LOCATION',
    ROLE_PINPOINT_AUDITOR: 'ROLE_PINPOINT_AUDITOR',
    ROLE_PINPOINT_ADMIN: 'ROLE_PINPOINT_ADMIN',
    ROLE_MERCHANT_ADMIN: 'ROLE_MERCHANT_ADMIN',
    ROLE_MERCHANT_ENTITY_ADMIN: 'ROLE_MERCHANT_ENTITY_ADMIN',
    ROLE_MERCHANT_LEARNING_ONLY: 'ROLE_MERCHANT_LEARNING_ONLY',
    ROLE_MERCHANT_USER: 'ROLE_MERCHANT_USER',
    ROLE_ASV_APPROVER: 'ROLE_ASV_APPROVER',
    ROLE_ADMIN: 'ROLE_ADMIN',
    ROLE_ACQUIRER_ADMIN: 'ROLE_ACQUIRER_ADMIN',
    ROLE_ACQUIRER_USER: 'ROLE_ACQUIRER_USER',
    ROLE_ACQUIRER_ENTITY_ADMIN: 'ROLE_ACQUIRER_ENTITY_ADMIN'
  }; // ROLE_MERCHANT_ADMIN - ( manage subscription ) + same stuff like for (ROLE_MERCHANT_ENTITY_ADMIN)
  // ROLE_MERCHANT_ENTITY_ADMIN

  _exports.RoleTypes = RoleTypes;

  var _default = (_RoleTypes$ROLE_PINPO = {}, (0, _defineProperty2.default)(_RoleTypes$ROLE_PINPO, RoleTypes.ROLE_PINPOINT_ADMIN, {
    id: 9,
    friendly: 'Admin',
    description: 'Can create new Zones, Areas, Locations and administer users'
  }), (0, _defineProperty2.default)(_RoleTypes$ROLE_PINPO, RoleTypes.ROLE_PINPOINT_MANAGER_ZONE, {
    id: 12,
    friendly: 'Zone Manager',
    description: 'Can create Areas and Locations in his Zone'
  }), (0, _defineProperty2.default)(_RoleTypes$ROLE_PINPO, RoleTypes.ROLE_PINPOINT_MANAGER_AREA, {
    id: 7,
    friendly: 'Area Manager',
    description: 'Can create Locations in his Area'
  }), (0, _defineProperty2.default)(_RoleTypes$ROLE_PINPO, RoleTypes.ROLE_PINPOINT_MANAGER_LOCATION, {
    id: 11,
    friendly: 'Location Manager',
    description: 'Can create Devices in his Location'
  }), (0, _defineProperty2.default)(_RoleTypes$ROLE_PINPO, RoleTypes.ROLE_PINPOINT_AUDITOR, {
    id: 8,
    friendly: 'Auditor',
    description: 'Can audit Devices in his Location'
  }), (0, _defineProperty2.default)(_RoleTypes$ROLE_PINPO, RoleTypes.ROLE_MERCHANT_ADMIN, {
    id: 1,
    friendly: 'Merchant Admin',
    description: ''
  }), (0, _defineProperty2.default)(_RoleTypes$ROLE_PINPO, RoleTypes.ROLE_MERCHANT_USER, {
    id: 2,
    friendly: 'Merchant User',
    description: ''
  }), (0, _defineProperty2.default)(_RoleTypes$ROLE_PINPO, RoleTypes.ROLE_ACQUIRER_ADMIN, {
    id: 3,
    friendly: 'MPA Admin',
    description: ''
  }), (0, _defineProperty2.default)(_RoleTypes$ROLE_PINPO, RoleTypes.ROLE_ACQUIRER_USER, {
    id: 4,
    friendly: 'MPA',
    description: ''
  }), (0, _defineProperty2.default)(_RoleTypes$ROLE_PINPO, RoleTypes.ROLE_MERCHANT_ENTITY_ADMIN, {
    id: 5,
    friendly: 'Merchant Admin',
    description: 'A user who is responsible for managing the Merchant: filling SAQs, launching ASV Scans, and so on'
  }), (0, _defineProperty2.default)(_RoleTypes$ROLE_PINPO, RoleTypes.ROLE_ACQUIRER_ENTITY_ADMIN, {
    id: 6,
    friendly: 'MPA Admin',
    description: ''
  }), (0, _defineProperty2.default)(_RoleTypes$ROLE_PINPO, RoleTypes.ROLE_MERCHANT_LEARNING_ONLY, {
    id: 13,
    friendly: 'Learning Only',
    description: 'A user who can attend the proposed trainings'
  }), _RoleTypes$ROLE_PINPO);

  _exports.default = _default;
});