define("zerorisk-frontend/mirage/serializers/asset-ip", ["exports", "zerorisk-frontend/mirage/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serializeIds: 'always'
  });

  _exports.default = _default;
});