define("zerorisk-frontend/templates/hierarchy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eDwnyjmA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n  \"],[1,[27,\"pg-navbar\",null,[[\"symbol\"],[\"sitemap\"]]],false],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row bg-white\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-sm-12\"],[9],[0,\"\\n        \"],[1,[27,\"mpa/hierarchy-view\",null,[[\"model\",\"redirectToMerchants\"],[[22,0,[\"model\"]],[27,\"action\",[[22,0,[]],\"redirectToMerchants\"],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/hierarchy.hbs"
    }
  });

  _exports.default = _default;
});