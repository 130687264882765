define("zerorisk-frontend/utils/mock-dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "saqs": {
      "compliancePercentage": 72.2,
      "types": {
        "SAQ_A": {
          "percentage": 32.9,
          "total": 431.0
        },
        "SAQ_A_EP": {
          "percentage": 4.9,
          "total": 64.0
        },
        "SAQ_B": {
          "percentage": 0.1,
          "total": 1.0
        },
        "SAQ_B_IP": {
          "percentage": 0.6,
          "total": 8.0
        },
        "SAQ_C": {
          "percentage": 0.4,
          "total": 5.0
        },
        "SAQ_C_VT": {
          "percentage": 2.2,
          "total": 29.0
        },
        "SAQ_D": {
          "percentage": 8.9,
          "total": 117.0
        },
        "SAQ_P2PE": {
          "percentage": 0.0,
          "total": 0.0
        },
        "ROC": {
          "percentage": 0.0,
          "total": 0.0
        },
        "RAQ": {
          "percentage": 0.0,
          "total": 0.0
        },
        "OTHER": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UPLOADED": {
          "percentage": 0.0,
          "total": 0.0
        }
      }
    },
    "assetIps": {
      "compliancePercentage": 26.7,
      "cvssScore": 4.6,
      "pciFailTotal": 11
    },
    "devices": {
      "compliancePercentage": 0.0,
      "totalDevices": 0
    },
    "merchants": [{
      "compliancePercentage": 0.0,
      "referenceLevel": null,
      "total": 1897,
      "types": {
        "LEVEL1": {
          "percentage": 0.1,
          "total": 2.0
        },
        "LEVEL2": {
          "percentage": 0.1,
          "total": 1.0
        },
        "LEVEL3": {
          "percentage": 5.3,
          "total": 101.0
        },
        "LEVEL4": {
          "percentage": 68.1,
          "total": 1292.0
        },
        "UNDETERMINED": {
          "percentage": 26.4,
          "total": 501.0
        }
      },
      "statuses": {
        "NotEnrolled": {
          "percentage": 45.6,
          "total": 865.0
        },
        "Enrolled": {
          "percentage": 5.0,
          "total": 94.0
        },
        "NotCompliant": {
          "percentage": 24.9,
          "total": 473.0
        },
        "Compliant": {
          "percentage": 24.5,
          "total": 465.0
        },
        "Closed": {
          "percentage": 0.0,
          "total": 0.0
        },
        "Compromised": {
          "percentage": 0.0,
          "total": 0.0
        },
        "AuditRequired": {
          "percentage": 0.0,
          "total": 0.0
        }
      },
      "channels": {
        "MOTO": {
          "percentage": 3.0,
          "total": 56.0
        },
        "E_COMMERCE": {
          "percentage": 81.8,
          "total": 1551.0
        },
        "F2F": {
          "percentage": 1.3,
          "total": 25.0
        },
        "OTHER": {
          "percentage": 0.2,
          "total": 3.0
        },
        "MULTI": {
          "percentage": 13.8,
          "total": 262.0
        },
        "UNDETERMINED": {
          "percentage": 0.0,
          "total": 0.0
        }
      },
      "initialRisks": {
        "LOW": {
          "percentage": 0.0,
          "total": 0.0
        },
        "MEDIUM": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.0,
          "total": 0.0
        }
      },
      "supplyChains": null,
      "saqTypes": {
        "SAQ_A_REDIRECT": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_A_IFRAME": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_A_EP": {
          "percentage": 2.9,
          "total": 55.0
        },
        "SAQ_B": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_B_IP": {
          "percentage": 0.4,
          "total": 8.0
        },
        "SAQ_C": {
          "percentage": 0.3,
          "total": 6.0
        },
        "SAQ_C_VT": {
          "percentage": 1.6,
          "total": 31.0
        },
        "SAQ_P2PE": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_D": {
          "percentage": 3.7,
          "total": 71.0
        },
        "ROC": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_A": {
          "percentage": 45.3,
          "total": 860.0
        },
        "UNSET": {
          "percentage": 45.7,
          "total": 866.0
        }
      },
      "globalTrustScoreLabels": {
        "CRITICAL": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.5,
          "total": 9.0
        },
        "MEDIUM": {
          "percentage": 1.7,
          "total": 32.0
        },
        "LOW": {
          "percentage": 2.5,
          "total": 47.0
        },
        "TRUSTED": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNDETERMINED": {
          "percentage": 95.4,
          "total": 1809.0
        }
      },
      "globalTrustScoreOverlayedLabels": {
        "CRITICAL": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.5,
          "total": 9.0
        },
        "MEDIUM": {
          "percentage": 1.7,
          "total": 32.0
        },
        "LOW": {
          "percentage": 2.5,
          "total": 47.0
        },
        "TRUSTED": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNDETERMINED": {
          "percentage": 95.4,
          "total": 1809.0
        }
      },
      "paymentPropertiesTrustScoreLabels": {
        "CRITICAL": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.5,
          "total": 9.0
        },
        "MEDIUM": {
          "percentage": 1.7,
          "total": 32.0
        },
        "LOW": {
          "percentage": 2.5,
          "total": 47.0
        },
        "TRUSTED": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNDETERMINED": {
          "percentage": 95.4,
          "total": 1809.0
        }
      },
      "paymentPropertiesTrustScoreOverlayedLabels": {
        "CRITICAL": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.5,
          "total": 9.0
        },
        "MEDIUM": {
          "percentage": 1.7,
          "total": 32.0
        },
        "LOW": {
          "percentage": 2.5,
          "total": 47.0
        },
        "TRUSTED": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNDETERMINED": {
          "percentage": 95.4,
          "total": 1809.0
        }
      },
      "complianceVersusRisks": [{
        "status": "Compliant",
        "scores": {
          "CRITICAL": {
            "percentage": 0.0,
            "total": 0.0
          },
          "HIGH": {
            "percentage": 0.2,
            "total": 4.0
          },
          "MEDIUM": {
            "percentage": 1.5,
            "total": 28.0
          },
          "LOW": {
            "percentage": 3.0,
            "total": 56.0
          },
          "TRUSTED": {
            "percentage": 0.0,
            "total": 0.0
          },
          "UNDETERMINED": {
            "percentage": 19.9,
            "total": 377.0
          }
        }
      }, {
        "status": "NotCompliant",
        "scores": {
          "CRITICAL": {
            "percentage": 0.0,
            "total": 0.0
          },
          "HIGH": {
            "percentage": 0.2,
            "total": 4.0
          },
          "MEDIUM": {
            "percentage": 0.3,
            "total": 6.0
          },
          "LOW": {
            "percentage": 0.9,
            "total": 17.0
          },
          "TRUSTED": {
            "percentage": 0.0,
            "total": 0.0
          },
          "UNDETERMINED": {
            "percentage": 23.5,
            "total": 446.0
          }
        }
      }, {
        "status": "Undetermined",
        "scores": {
          "CRITICAL": {
            "percentage": 0.0,
            "total": 0.0
          },
          "HIGH": {
            "percentage": 0.0,
            "total": 18.0
          },
          "MEDIUM": {
            "percentage": 0.0,
            "total": 23.0
          },
          "LOW": {
            "percentage": 0.0,
            "total": 15.0
          },
          "TRUSTED": {
            "percentage": 0.0,
            "total": 0.0
          },
          "UNDETERMINED": {
            "percentage": 0.0,
            "total": 903.0
          }
        }
      }, {
        "status": "Global",
        "scores": {
          "CRITICAL": {
            "percentage": 0.0,
            "total": 0.0
          },
          "HIGH": {
            "percentage": 0.5,
            "total": 9.0
          },
          "MEDIUM": {
            "percentage": 1.7,
            "total": 32.0
          },
          "LOW": {
            "percentage": 2.5,
            "total": 47.0
          },
          "TRUSTED": {
            "percentage": 0.0,
            "total": 0.0
          },
          "UNDETERMINED": {
            "percentage": 95.4,
            "total": 1809.0
          }
        }
      }],
      "darkBeamScore": 0,
      "globalTrustScore": 75,
      "globalTrustScoreOverlayed": 67,
      "paymentPropertiesTrustScore": 76,
      "paymentPropertiesTrustScoreOverlayed": 68,
      "flagged": 68,
      "highRiskMccTotal": 0,
      "highRiskMccPercentage": 0.0,
      "worst5Merchants": [{
        "merchantId": 4990,
        "companyName": "IHP Ltd - Jersey Hemp"
      }, {
        "merchantId": 4680,
        "companyName": "Turnstyle Designs Ltd"
      }, {
        "merchantId": 4939,
        "companyName": "TPC Solutions Ltd"
      }, {
        "merchantId": 6733,
        "companyName": "HAUGHTON HALL LIMITED"
      }, {
        "merchantId": 5736,
        "companyName": "The Empress Hotel Ltd"
      }]
    }, {
      "compliancePercentage": 0.0,
      "referenceLevel": "LEVEL1",
      "total": 2,
      "types": {},
      "statuses": {
        "NotEnrolled": {
          "percentage": 100.0,
          "total": 2.0
        },
        "Enrolled": {
          "percentage": 0.0,
          "total": 0.0
        },
        "NotCompliant": {
          "percentage": 0.0,
          "total": 0.0
        },
        "Compliant": {
          "percentage": 0.0,
          "total": 0.0
        },
        "Closed": {
          "percentage": 0.0,
          "total": 0.0
        },
        "Compromised": {
          "percentage": 0.0,
          "total": 0.0
        },
        "AuditRequired": {
          "percentage": 0.0,
          "total": 0.0
        }
      },
      "channels": {
        "MOTO": {
          "percentage": 0.0,
          "total": 0.0
        },
        "E_COMMERCE": {
          "percentage": 50.0,
          "total": 1.0
        },
        "F2F": {
          "percentage": 0.0,
          "total": 0.0
        },
        "OTHER": {
          "percentage": 0.0,
          "total": 0.0
        },
        "MULTI": {
          "percentage": 50.0,
          "total": 1.0
        },
        "UNDETERMINED": {
          "percentage": 0.0,
          "total": 0.0
        }
      },
      "initialRisks": {
        "LOW": {
          "percentage": 0.0,
          "total": 0.0
        },
        "MEDIUM": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.0,
          "total": 0.0
        }
      },
      "supplyChains": null,
      "saqTypes": {
        "SAQ_A_REDIRECT": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_A_IFRAME": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_A_EP": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_B": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_B_IP": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_C": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_C_VT": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_P2PE": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_D": {
          "percentage": 0.0,
          "total": 0.0
        },
        "ROC": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_A": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNSET": {
          "percentage": 100.0,
          "total": 2.0
        }
      },
      "globalTrustScoreLabels": {
        "CRITICAL": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.0,
          "total": 0.0
        },
        "MEDIUM": {
          "percentage": 0.0,
          "total": 0.0
        },
        "LOW": {
          "percentage": 0.0,
          "total": 0.0
        },
        "TRUSTED": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNDETERMINED": {
          "percentage": 100.0,
          "total": 2.0
        }
      },
      "globalTrustScoreOverlayedLabels": {
        "CRITICAL": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.0,
          "total": 0.0
        },
        "MEDIUM": {
          "percentage": 0.0,
          "total": 0.0
        },
        "LOW": {
          "percentage": 0.0,
          "total": 0.0
        },
        "TRUSTED": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNDETERMINED": {
          "percentage": 100.0,
          "total": 2.0
        }
      },
      "paymentPropertiesTrustScoreLabels": {
        "CRITICAL": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.0,
          "total": 0.0
        },
        "MEDIUM": {
          "percentage": 0.0,
          "total": 0.0
        },
        "LOW": {
          "percentage": 0.0,
          "total": 0.0
        },
        "TRUSTED": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNDETERMINED": {
          "percentage": 100.0,
          "total": 2.0
        }
      },
      "paymentPropertiesTrustScoreOverlayedLabels": {
        "CRITICAL": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.0,
          "total": 0.0
        },
        "MEDIUM": {
          "percentage": 0.0,
          "total": 0.0
        },
        "LOW": {
          "percentage": 0.0,
          "total": 0.0
        },
        "TRUSTED": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNDETERMINED": {
          "percentage": 100.0,
          "total": 2.0
        }
      },
      "complianceVersusRisks": [{
        "status": "Compliant",
        "scores": {
          "CRITICAL": {
            "percentage": 0.0,
            "total": 0.0
          },
          "HIGH": {
            "percentage": 0.0,
            "total": 0.0
          },
          "MEDIUM": {
            "percentage": 0.0,
            "total": 0.0
          },
          "LOW": {
            "percentage": 0.0,
            "total": 0.0
          },
          "TRUSTED": {
            "percentage": 0.0,
            "total": 0.0
          },
          "UNDETERMINED": {
            "percentage": 0.0,
            "total": 0.0
          }
        }
      }, {
        "status": "NotCompliant",
        "scores": {
          "CRITICAL": {
            "percentage": 0.0,
            "total": 0.0
          },
          "HIGH": {
            "percentage": 0.0,
            "total": 0.0
          },
          "MEDIUM": {
            "percentage": 0.0,
            "total": 0.0
          },
          "LOW": {
            "percentage": 0.0,
            "total": 0.0
          },
          "TRUSTED": {
            "percentage": 0.0,
            "total": 0.0
          },
          "UNDETERMINED": {
            "percentage": 0.0,
            "total": 0.0
          }
        }
      }, {
        "status": "Undetermined",
        "scores": {
          "CRITICAL": {
            "percentage": 0.0,
            "total": 0.0
          },
          "HIGH": {
            "percentage": 0.0,
            "total": 0.0
          },
          "MEDIUM": {
            "percentage": 0.0,
            "total": 0.0
          },
          "LOW": {
            "percentage": 0.0,
            "total": 0.0
          },
          "TRUSTED": {
            "percentage": 0.0,
            "total": 0.0
          },
          "UNDETERMINED": {
            "percentage": 0.0,
            "total": 2.0
          }
        }
      }, {
        "status": "Global",
        "scores": {
          "CRITICAL": {
            "percentage": 0.0,
            "total": 0.0
          },
          "HIGH": {
            "percentage": 0.0,
            "total": 0.0
          },
          "MEDIUM": {
            "percentage": 0.0,
            "total": 0.0
          },
          "LOW": {
            "percentage": 0.0,
            "total": 0.0
          },
          "TRUSTED": {
            "percentage": 0.0,
            "total": 0.0
          },
          "UNDETERMINED": {
            "percentage": 100.0,
            "total": 2.0
          }
        }
      }],
      "darkBeamScore": 0,
      "globalTrustScore": 0,
      "globalTrustScoreOverlayed": 0,
      "paymentPropertiesTrustScore": 0,
      "paymentPropertiesTrustScoreOverlayed": 0,
      "flagged": 0,
      "highRiskMccTotal": 0,
      "highRiskMccPercentage": 0.0,
      "worst5Merchants": null
    }, {
      "compliancePercentage": 0.0,
      "referenceLevel": "LEVEL2",
      "total": 1,
      "types": {},
      "statuses": {
        "NotEnrolled": {
          "percentage": 100.0,
          "total": 1.0
        },
        "Enrolled": {
          "percentage": 0.0,
          "total": 0.0
        },
        "NotCompliant": {
          "percentage": 0.0,
          "total": 0.0
        },
        "Compliant": {
          "percentage": 0.0,
          "total": 0.0
        },
        "Closed": {
          "percentage": 0.0,
          "total": 0.0
        },
        "Compromised": {
          "percentage": 0.0,
          "total": 0.0
        },
        "AuditRequired": {
          "percentage": 0.0,
          "total": 0.0
        }
      },
      "channels": {
        "MOTO": {
          "percentage": 0.0,
          "total": 0.0
        },
        "E_COMMERCE": {
          "percentage": 0.0,
          "total": 0.0
        },
        "F2F": {
          "percentage": 0.0,
          "total": 0.0
        },
        "OTHER": {
          "percentage": 0.0,
          "total": 0.0
        },
        "MULTI": {
          "percentage": 100.0,
          "total": 1.0
        },
        "UNDETERMINED": {
          "percentage": 0.0,
          "total": 0.0
        }
      },
      "initialRisks": {
        "LOW": {
          "percentage": 0.0,
          "total": 0.0
        },
        "MEDIUM": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.0,
          "total": 0.0
        }
      },
      "supplyChains": null,
      "saqTypes": {
        "SAQ_A_REDIRECT": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_A_IFRAME": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_A_EP": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_B": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_B_IP": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_C": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_C_VT": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_P2PE": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_D": {
          "percentage": 0.0,
          "total": 0.0
        },
        "ROC": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_A": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNSET": {
          "percentage": 100.0,
          "total": 1.0
        }
      },
      "globalTrustScoreLabels": {
        "CRITICAL": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.0,
          "total": 0.0
        },
        "MEDIUM": {
          "percentage": 0.0,
          "total": 0.0
        },
        "LOW": {
          "percentage": 0.0,
          "total": 0.0
        },
        "TRUSTED": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNDETERMINED": {
          "percentage": 100.0,
          "total": 1.0
        }
      },
      "globalTrustScoreOverlayedLabels": {
        "CRITICAL": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.0,
          "total": 0.0
        },
        "MEDIUM": {
          "percentage": 0.0,
          "total": 0.0
        },
        "LOW": {
          "percentage": 0.0,
          "total": 0.0
        },
        "TRUSTED": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNDETERMINED": {
          "percentage": 100.0,
          "total": 1.0
        }
      },
      "paymentPropertiesTrustScoreLabels": {
        "CRITICAL": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.0,
          "total": 0.0
        },
        "MEDIUM": {
          "percentage": 0.0,
          "total": 0.0
        },
        "LOW": {
          "percentage": 0.0,
          "total": 0.0
        },
        "TRUSTED": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNDETERMINED": {
          "percentage": 100.0,
          "total": 1.0
        }
      },
      "paymentPropertiesTrustScoreOverlayedLabels": {
        "CRITICAL": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.0,
          "total": 0.0
        },
        "MEDIUM": {
          "percentage": 0.0,
          "total": 0.0
        },
        "LOW": {
          "percentage": 0.0,
          "total": 0.0
        },
        "TRUSTED": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNDETERMINED": {
          "percentage": 100.0,
          "total": 1.0
        }
      },
      "complianceVersusRisks": [{
        "status": "Compliant",
        "scores": {
          "CRITICAL": {
            "percentage": 0.0,
            "total": 0.0
          },
          "HIGH": {
            "percentage": 0.0,
            "total": 0.0
          },
          "MEDIUM": {
            "percentage": 0.0,
            "total": 0.0
          },
          "LOW": {
            "percentage": 0.0,
            "total": 0.0
          },
          "TRUSTED": {
            "percentage": 0.0,
            "total": 0.0
          },
          "UNDETERMINED": {
            "percentage": 0.0,
            "total": 0.0
          }
        }
      }, {
        "status": "NotCompliant",
        "scores": {
          "CRITICAL": {
            "percentage": 0.0,
            "total": 0.0
          },
          "HIGH": {
            "percentage": 0.0,
            "total": 0.0
          },
          "MEDIUM": {
            "percentage": 0.0,
            "total": 0.0
          },
          "LOW": {
            "percentage": 0.0,
            "total": 0.0
          },
          "TRUSTED": {
            "percentage": 0.0,
            "total": 0.0
          },
          "UNDETERMINED": {
            "percentage": 0.0,
            "total": 0.0
          }
        }
      }, {
        "status": "Undetermined",
        "scores": {
          "CRITICAL": {
            "percentage": 0.0,
            "total": 0.0
          },
          "HIGH": {
            "percentage": 0.0,
            "total": 0.0
          },
          "MEDIUM": {
            "percentage": 0.0,
            "total": 0.0
          },
          "LOW": {
            "percentage": 0.0,
            "total": 0.0
          },
          "TRUSTED": {
            "percentage": 0.0,
            "total": 0.0
          },
          "UNDETERMINED": {
            "percentage": 0.0,
            "total": 1.0
          }
        }
      }, {
        "status": "Global",
        "scores": {
          "CRITICAL": {
            "percentage": 0.0,
            "total": 0.0
          },
          "HIGH": {
            "percentage": 0.0,
            "total": 0.0
          },
          "MEDIUM": {
            "percentage": 0.0,
            "total": 0.0
          },
          "LOW": {
            "percentage": 0.0,
            "total": 0.0
          },
          "TRUSTED": {
            "percentage": 0.0,
            "total": 0.0
          },
          "UNDETERMINED": {
            "percentage": 100.0,
            "total": 1.0
          }
        }
      }],
      "darkBeamScore": 0,
      "globalTrustScore": 0,
      "globalTrustScoreOverlayed": 0,
      "paymentPropertiesTrustScore": 0,
      "paymentPropertiesTrustScoreOverlayed": 0,
      "flagged": 0,
      "highRiskMccTotal": 0,
      "highRiskMccPercentage": 0.0,
      "worst5Merchants": null
    }, {
      "compliancePercentage": 0.0,
      "referenceLevel": "LEVEL3",
      "total": 101,
      "types": {},
      "statuses": {
        "NotEnrolled": {
          "percentage": 52.5,
          "total": 53.0
        },
        "Enrolled": {
          "percentage": 2.0,
          "total": 2.0
        },
        "NotCompliant": {
          "percentage": 19.8,
          "total": 20.0
        },
        "Compliant": {
          "percentage": 25.7,
          "total": 26.0
        },
        "Closed": {
          "percentage": 0.0,
          "total": 0.0
        },
        "Compromised": {
          "percentage": 0.0,
          "total": 0.0
        },
        "AuditRequired": {
          "percentage": 0.0,
          "total": 0.0
        }
      },
      "channels": {
        "MOTO": {
          "percentage": 1.0,
          "total": 1.0
        },
        "E_COMMERCE": {
          "percentage": 91.1,
          "total": 92.0
        },
        "F2F": {
          "percentage": 0.0,
          "total": 0.0
        },
        "OTHER": {
          "percentage": 0.0,
          "total": 0.0
        },
        "MULTI": {
          "percentage": 7.9,
          "total": 8.0
        },
        "UNDETERMINED": {
          "percentage": 0.0,
          "total": 0.0
        }
      },
      "initialRisks": {
        "LOW": {
          "percentage": 0.0,
          "total": 0.0
        },
        "MEDIUM": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.0,
          "total": 0.0
        }
      },
      "supplyChains": null,
      "saqTypes": {
        "SAQ_A_REDIRECT": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_A_IFRAME": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_A_EP": {
          "percentage": 2.0,
          "total": 2.0
        },
        "SAQ_B": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_B_IP": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_C": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_C_VT": {
          "percentage": 1.0,
          "total": 1.0
        },
        "SAQ_P2PE": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_D": {
          "percentage": 5.0,
          "total": 5.0
        },
        "ROC": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_A": {
          "percentage": 39.6,
          "total": 40.0
        },
        "UNSET": {
          "percentage": 52.5,
          "total": 53.0
        }
      },
      "globalTrustScoreLabels": {
        "CRITICAL": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 1.0,
          "total": 1.0
        },
        "MEDIUM": {
          "percentage": 1.0,
          "total": 1.0
        },
        "LOW": {
          "percentage": 1.0,
          "total": 1.0
        },
        "TRUSTED": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNDETERMINED": {
          "percentage": 97.0,
          "total": 98.0
        }
      },
      "globalTrustScoreOverlayedLabels": {
        "CRITICAL": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 1.0,
          "total": 1.0
        },
        "MEDIUM": {
          "percentage": 1.0,
          "total": 1.0
        },
        "LOW": {
          "percentage": 1.0,
          "total": 1.0
        },
        "TRUSTED": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNDETERMINED": {
          "percentage": 97.0,
          "total": 98.0
        }
      },
      "paymentPropertiesTrustScoreLabels": {
        "CRITICAL": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 1.0,
          "total": 1.0
        },
        "MEDIUM": {
          "percentage": 1.0,
          "total": 1.0
        },
        "LOW": {
          "percentage": 1.0,
          "total": 1.0
        },
        "TRUSTED": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNDETERMINED": {
          "percentage": 97.0,
          "total": 98.0
        }
      },
      "paymentPropertiesTrustScoreOverlayedLabels": {
        "CRITICAL": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 1.0,
          "total": 1.0
        },
        "MEDIUM": {
          "percentage": 1.0,
          "total": 1.0
        },
        "LOW": {
          "percentage": 1.0,
          "total": 1.0
        },
        "TRUSTED": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNDETERMINED": {
          "percentage": 97.0,
          "total": 98.0
        }
      },
      "complianceVersusRisks": [{
        "status": "Compliant",
        "scores": {
          "CRITICAL": {
            "percentage": 0.0,
            "total": 0.0
          },
          "HIGH": {
            "percentage": 1.0,
            "total": 1.0
          },
          "MEDIUM": {
            "percentage": 2.0,
            "total": 2.0
          },
          "LOW": {
            "percentage": 2.0,
            "total": 2.0
          },
          "TRUSTED": {
            "percentage": 0.0,
            "total": 0.0
          },
          "UNDETERMINED": {
            "percentage": 20.8,
            "total": 21.0
          }
        }
      }, {
        "status": "NotCompliant",
        "scores": {
          "CRITICAL": {
            "percentage": 0.0,
            "total": 0.0
          },
          "HIGH": {
            "percentage": 0.0,
            "total": 0.0
          },
          "MEDIUM": {
            "percentage": 0.0,
            "total": 0.0
          },
          "LOW": {
            "percentage": 0.0,
            "total": 0.0
          },
          "TRUSTED": {
            "percentage": 0.0,
            "total": 0.0
          },
          "UNDETERMINED": {
            "percentage": 19.8,
            "total": 20.0
          }
        }
      }, {
        "status": "Undetermined",
        "scores": {
          "CRITICAL": {
            "percentage": 0.0,
            "total": 0.0
          },
          "HIGH": {
            "percentage": 0.0,
            "total": 0.0
          },
          "MEDIUM": {
            "percentage": 0.0,
            "total": 1.0
          },
          "LOW": {
            "percentage": 0.0,
            "total": 1.0
          },
          "TRUSTED": {
            "percentage": 0.0,
            "total": 0.0
          },
          "UNDETERMINED": {
            "percentage": 0.0,
            "total": 53.0
          }
        }
      }, {
        "status": "Global",
        "scores": {
          "CRITICAL": {
            "percentage": 0.0,
            "total": 0.0
          },
          "HIGH": {
            "percentage": 1.0,
            "total": 1.0
          },
          "MEDIUM": {
            "percentage": 1.0,
            "total": 1.0
          },
          "LOW": {
            "percentage": 1.0,
            "total": 1.0
          },
          "TRUSTED": {
            "percentage": 0.0,
            "total": 0.0
          },
          "UNDETERMINED": {
            "percentage": 97.0,
            "total": 98.0
          }
        }
      }],
      "darkBeamScore": 0,
      "globalTrustScore": 75,
      "globalTrustScoreOverlayed": 66,
      "paymentPropertiesTrustScore": 67,
      "paymentPropertiesTrustScoreOverlayed": 64,
      "flagged": 4,
      "highRiskMccTotal": 0,
      "highRiskMccPercentage": 0.0,
      "worst5Merchants": null
    }, {
      "compliancePercentage": 0.0,
      "referenceLevel": "LEVEL4",
      "total": 1292,
      "types": {},
      "statuses": {
        "NotEnrolled": {
          "percentage": 37.6,
          "total": 486.0
        },
        "Enrolled": {
          "percentage": 4.3,
          "total": 55.0
        },
        "NotCompliant": {
          "percentage": 33.0,
          "total": 426.0
        },
        "Compliant": {
          "percentage": 25.2,
          "total": 325.0
        },
        "Closed": {
          "percentage": 0.0,
          "total": 0.0
        },
        "Compromised": {
          "percentage": 0.0,
          "total": 0.0
        },
        "AuditRequired": {
          "percentage": 0.0,
          "total": 0.0
        }
      },
      "channels": {
        "MOTO": {
          "percentage": 1.5,
          "total": 20.0
        },
        "E_COMMERCE": {
          "percentage": 82.0,
          "total": 1060.0
        },
        "F2F": {
          "percentage": 0.7,
          "total": 9.0
        },
        "OTHER": {
          "percentage": 0.0,
          "total": 0.0
        },
        "MULTI": {
          "percentage": 15.7,
          "total": 203.0
        },
        "UNDETERMINED": {
          "percentage": 0.0,
          "total": 0.0
        }
      },
      "initialRisks": {
        "LOW": {
          "percentage": 0.0,
          "total": 0.0
        },
        "MEDIUM": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.0,
          "total": 0.0
        }
      },
      "supplyChains": null,
      "saqTypes": {
        "SAQ_A_REDIRECT": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_A_IFRAME": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_A_EP": {
          "percentage": 1.7,
          "total": 22.0
        },
        "SAQ_B": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_B_IP": {
          "percentage": 0.3,
          "total": 4.0
        },
        "SAQ_C": {
          "percentage": 0.3,
          "total": 4.0
        },
        "SAQ_C_VT": {
          "percentage": 1.2,
          "total": 16.0
        },
        "SAQ_P2PE": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_D": {
          "percentage": 3.0,
          "total": 39.0
        },
        "ROC": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_A": {
          "percentage": 55.7,
          "total": 720.0
        },
        "UNSET": {
          "percentage": 37.7,
          "total": 487.0
        }
      },
      "globalTrustScoreLabels": {
        "CRITICAL": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.5,
          "total": 6.0
        },
        "MEDIUM": {
          "percentage": 1.3,
          "total": 17.0
        },
        "LOW": {
          "percentage": 1.5,
          "total": 20.0
        },
        "TRUSTED": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNDETERMINED": {
          "percentage": 96.7,
          "total": 1249.0
        }
      },
      "globalTrustScoreOverlayedLabels": {
        "CRITICAL": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.5,
          "total": 6.0
        },
        "MEDIUM": {
          "percentage": 1.3,
          "total": 17.0
        },
        "LOW": {
          "percentage": 1.5,
          "total": 20.0
        },
        "TRUSTED": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNDETERMINED": {
          "percentage": 96.7,
          "total": 1249.0
        }
      },
      "paymentPropertiesTrustScoreLabels": {
        "CRITICAL": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.5,
          "total": 6.0
        },
        "MEDIUM": {
          "percentage": 1.3,
          "total": 17.0
        },
        "LOW": {
          "percentage": 1.5,
          "total": 20.0
        },
        "TRUSTED": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNDETERMINED": {
          "percentage": 96.7,
          "total": 1249.0
        }
      },
      "paymentPropertiesTrustScoreOverlayedLabels": {
        "CRITICAL": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.5,
          "total": 6.0
        },
        "MEDIUM": {
          "percentage": 1.3,
          "total": 17.0
        },
        "LOW": {
          "percentage": 1.5,
          "total": 20.0
        },
        "TRUSTED": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNDETERMINED": {
          "percentage": 96.7,
          "total": 1249.0
        }
      },
      "complianceVersusRisks": [{
        "status": "Compliant",
        "scores": {
          "CRITICAL": {
            "percentage": 0.0,
            "total": 0.0
          },
          "HIGH": {
            "percentage": 0.2,
            "total": 2.0
          },
          "MEDIUM": {
            "percentage": 0.8,
            "total": 10.0
          },
          "LOW": {
            "percentage": 2.2,
            "total": 28.0
          },
          "TRUSTED": {
            "percentage": 0.0,
            "total": 0.0
          },
          "UNDETERMINED": {
            "percentage": 22.1,
            "total": 285.0
          }
        }
      }, {
        "status": "NotCompliant",
        "scores": {
          "CRITICAL": {
            "percentage": 0.0,
            "total": 0.0
          },
          "HIGH": {
            "percentage": 0.3,
            "total": 4.0
          },
          "MEDIUM": {
            "percentage": 0.4,
            "total": 5.0
          },
          "LOW": {
            "percentage": 0.7,
            "total": 9.0
          },
          "TRUSTED": {
            "percentage": 0.0,
            "total": 0.0
          },
          "UNDETERMINED": {
            "percentage": 31.6,
            "total": 408.0
          }
        }
      }, {
        "status": "Undetermined",
        "scores": {
          "CRITICAL": {
            "percentage": 0.0,
            "total": 0.0
          },
          "HIGH": {
            "percentage": 0.0,
            "total": 12.0
          },
          "MEDIUM": {
            "percentage": 0.0,
            "total": 14.0
          },
          "LOW": {
            "percentage": 0.0,
            "total": 9.0
          },
          "TRUSTED": {
            "percentage": 0.0,
            "total": 0.0
          },
          "UNDETERMINED": {
            "percentage": 0.0,
            "total": 506.0
          }
        }
      }, {
        "status": "Global",
        "scores": {
          "CRITICAL": {
            "percentage": 0.0,
            "total": 0.0
          },
          "HIGH": {
            "percentage": 0.5,
            "total": 6.0
          },
          "MEDIUM": {
            "percentage": 1.3,
            "total": 17.0
          },
          "LOW": {
            "percentage": 1.5,
            "total": 20.0
          },
          "TRUSTED": {
            "percentage": 0.0,
            "total": 0.0
          },
          "UNDETERMINED": {
            "percentage": 96.7,
            "total": 1249.0
          }
        }
      }],
      "darkBeamScore": 0,
      "globalTrustScore": 74,
      "globalTrustScoreOverlayed": 66,
      "paymentPropertiesTrustScore": 74,
      "paymentPropertiesTrustScoreOverlayed": 67,
      "flagged": 38,
      "highRiskMccTotal": 0,
      "highRiskMccPercentage": 0.0,
      "worst5Merchants": null
    }, {
      "compliancePercentage": 0.0,
      "referenceLevel": "UNDETERMINED",
      "total": 501,
      "types": {},
      "statuses": {
        "NotEnrolled": {
          "percentage": 64.5,
          "total": 323.0
        },
        "Enrolled": {
          "percentage": 7.4,
          "total": 37.0
        },
        "NotCompliant": {
          "percentage": 5.4,
          "total": 27.0
        },
        "Compliant": {
          "percentage": 22.8,
          "total": 114.0
        },
        "Closed": {
          "percentage": 0.0,
          "total": 0.0
        },
        "Compromised": {
          "percentage": 0.0,
          "total": 0.0
        },
        "AuditRequired": {
          "percentage": 0.0,
          "total": 0.0
        }
      },
      "channels": {
        "MOTO": {
          "percentage": 7.0,
          "total": 35.0
        },
        "E_COMMERCE": {
          "percentage": 79.4,
          "total": 398.0
        },
        "F2F": {
          "percentage": 3.2,
          "total": 16.0
        },
        "OTHER": {
          "percentage": 0.6,
          "total": 3.0
        },
        "MULTI": {
          "percentage": 9.8,
          "total": 49.0
        },
        "UNDETERMINED": {
          "percentage": 0.0,
          "total": 0.0
        }
      },
      "initialRisks": {
        "LOW": {
          "percentage": 0.0,
          "total": 0.0
        },
        "MEDIUM": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.0,
          "total": 0.0
        }
      },
      "supplyChains": null,
      "saqTypes": {
        "SAQ_A_REDIRECT": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_A_IFRAME": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_A_EP": {
          "percentage": 6.2,
          "total": 31.0
        },
        "SAQ_B": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_B_IP": {
          "percentage": 0.8,
          "total": 4.0
        },
        "SAQ_C": {
          "percentage": 0.4,
          "total": 2.0
        },
        "SAQ_C_VT": {
          "percentage": 2.8,
          "total": 14.0
        },
        "SAQ_P2PE": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_D": {
          "percentage": 5.4,
          "total": 27.0
        },
        "ROC": {
          "percentage": 0.0,
          "total": 0.0
        },
        "SAQ_A": {
          "percentage": 20.0,
          "total": 100.0
        },
        "UNSET": {
          "percentage": 64.5,
          "total": 323.0
        }
      },
      "globalTrustScoreLabels": {
        "CRITICAL": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.4,
          "total": 2.0
        },
        "MEDIUM": {
          "percentage": 2.8,
          "total": 14.0
        },
        "LOW": {
          "percentage": 5.2,
          "total": 26.0
        },
        "TRUSTED": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNDETERMINED": {
          "percentage": 91.6,
          "total": 459.0
        }
      },
      "globalTrustScoreOverlayedLabels": {
        "CRITICAL": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.4,
          "total": 2.0
        },
        "MEDIUM": {
          "percentage": 2.8,
          "total": 14.0
        },
        "LOW": {
          "percentage": 5.2,
          "total": 26.0
        },
        "TRUSTED": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNDETERMINED": {
          "percentage": 91.6,
          "total": 459.0
        }
      },
      "paymentPropertiesTrustScoreLabels": {
        "CRITICAL": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.4,
          "total": 2.0
        },
        "MEDIUM": {
          "percentage": 2.8,
          "total": 14.0
        },
        "LOW": {
          "percentage": 5.2,
          "total": 26.0
        },
        "TRUSTED": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNDETERMINED": {
          "percentage": 91.6,
          "total": 459.0
        }
      },
      "paymentPropertiesTrustScoreOverlayedLabels": {
        "CRITICAL": {
          "percentage": 0.0,
          "total": 0.0
        },
        "HIGH": {
          "percentage": 0.4,
          "total": 2.0
        },
        "MEDIUM": {
          "percentage": 2.8,
          "total": 14.0
        },
        "LOW": {
          "percentage": 5.2,
          "total": 26.0
        },
        "TRUSTED": {
          "percentage": 0.0,
          "total": 0.0
        },
        "UNDETERMINED": {
          "percentage": 91.6,
          "total": 459.0
        }
      },
      "complianceVersusRisks": [{
        "status": "Compliant",
        "scores": {
          "CRITICAL": {
            "percentage": 0.0,
            "total": 0.0
          },
          "HIGH": {
            "percentage": 0.2,
            "total": 1.0
          },
          "MEDIUM": {
            "percentage": 3.2,
            "total": 16.0
          },
          "LOW": {
            "percentage": 5.2,
            "total": 26.0
          },
          "TRUSTED": {
            "percentage": 0.0,
            "total": 0.0
          },
          "UNDETERMINED": {
            "percentage": 14.2,
            "total": 71.0
          }
        }
      }, {
        "status": "NotCompliant",
        "scores": {
          "CRITICAL": {
            "percentage": 0.0,
            "total": 0.0
          },
          "HIGH": {
            "percentage": 0.0,
            "total": 0.0
          },
          "MEDIUM": {
            "percentage": 0.2,
            "total": 1.0
          },
          "LOW": {
            "percentage": 1.6,
            "total": 8.0
          },
          "TRUSTED": {
            "percentage": 0.0,
            "total": 0.0
          },
          "UNDETERMINED": {
            "percentage": 3.6,
            "total": 18.0
          }
        }
      }, {
        "status": "Undetermined",
        "scores": {
          "CRITICAL": {
            "percentage": 0.0,
            "total": 0.0
          },
          "HIGH": {
            "percentage": 0.0,
            "total": 6.0
          },
          "MEDIUM": {
            "percentage": 0.0,
            "total": 8.0
          },
          "LOW": {
            "percentage": 0.0,
            "total": 5.0
          },
          "TRUSTED": {
            "percentage": 0.0,
            "total": 0.0
          },
          "UNDETERMINED": {
            "percentage": 0.0,
            "total": 341.0
          }
        }
      }, {
        "status": "Global",
        "scores": {
          "CRITICAL": {
            "percentage": 0.0,
            "total": 0.0
          },
          "HIGH": {
            "percentage": 0.4,
            "total": 2.0
          },
          "MEDIUM": {
            "percentage": 2.8,
            "total": 14.0
          },
          "LOW": {
            "percentage": 5.2,
            "total": 26.0
          },
          "TRUSTED": {
            "percentage": 0.0,
            "total": 0.0
          },
          "UNDETERMINED": {
            "percentage": 91.6,
            "total": 459.0
          }
        }
      }],
      "darkBeamScore": 0,
      "globalTrustScore": 77,
      "globalTrustScoreOverlayed": 68,
      "paymentPropertiesTrustScore": 80,
      "paymentPropertiesTrustScoreOverlayed": 70,
      "flagged": 26,
      "highRiskMccTotal": 0,
      "highRiskMccPercentage": 0.0,
      "worst5Merchants": null
    }],
    "created": 1602239981894
  };
  _exports.default = _default;
});