define("zerorisk-frontend/templates/components/file-tools/new-file-creator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hgHDWVI8",
    "block": "{\"symbols\":[\"@useDefaultType\",\"@entities\"],\"statements\":[[4,\"unless\",[[23,[\"filePendingToUpload\"]]],null,{\"statements\":[[4,\"file-tools/uploader\",null,[[\"onfileadd\"],[[27,\"action\",[[22,0,[]],\"onfileadd\"],null]]],{\"statements\":[[0,\"    \"],[7,\"h3\"],[11,\"class\",\"text-center file-uploader-title\"],[9],[1,[27,\"t\",[\"files.newfile.upload_file\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"file-tools/edit-form\",null,[[\"entities\",\"useDefaultType\",\"onSubmit\",\"onCancel\"],[[22,2,[]],[22,1,[]],[27,\"action\",[[22,0,[]],\"saveFile\"],null],[27,\"action\",[[22,0,[]],\"cancel\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/file-tools/new-file-creator.hbs"
    }
  });

  _exports.default = _default;
});