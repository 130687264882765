define("zerorisk-frontend/templates/files/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I0dXLtr/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"content files-repository-container\"],[9],[0,\"\\n\\n  \"],[1,[27,\"pg-navbar\",null,[[\"symbol\"],[\"file\"]]],false],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"container p-t-20 p-b-20 p-l-20 p-r-20\"],[9],[0,\"\\n    \"],[1,[27,\"file-tools/edit-form\",null,[[\"fileRecord\",\"entities\",\"onSubmit\",\"onCancel\"],[[22,0,[\"file\"]],[22,0,[\"entities\"]],[27,\"action\",[[22,0,[]],\"saveFile\",[22,0,[\"file\"]]],null],[27,\"action\",[[22,0,[]],\"cancel\",[22,0,[\"file\"]]],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/files/edit.hbs"
    }
  });

  _exports.default = _default;
});