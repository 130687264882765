define("zerorisk-frontend/templates/components/security/compliance-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ClfSwGbK",
    "block": "{\"symbols\":[\"@status\"],\"statements\":[[7,\"div\"],[11,\"class\",\"card card-stock\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card-header d-flex p-0 justify-content-between align-items-center\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card-title p-mb-2 p-mr-2\"],[9],[0,\"\\n      \"],[7,\"h5\"],[9],[0,\"Compliance Status\"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card-body p-0 m-t-20\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"status-container\"],[9],[0,\"\\n      \"],[7,\"div\"],[12,\"class\",[28,[\"card-risk-status \",[27,\"enum-to-friendly\",[[22,1,[]],\"bgClass\"],null],\"-highlighted p-t-20\"]]],[9],[0,\"\\n        \"],[7,\"span\"],[12,\"class\",[28,[\"font-montserrat fs-14 all-caps \",[27,\"if\",[[27,\"eq\",[[22,1,[]],\"NotEnrolled\"],null],\"text-master\",\"text-white\"],null],\" light\"]]],[9],[0,\"\\n          \"],[1,[27,\"t\",[\"app.status\"],null],false],[0,\":\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"span\"],[12,\"class\",[28,[\"font-montserrat fs-14 all-caps \",[27,\"if\",[[27,\"eq\",[[22,1,[]],\"NotEnrolled\"],null],\"text-master\",\"text-white\"],null],\" bold\"]]],[9],[0,\"\\n          \"],[1,[27,\"enum-to-friendly\",[[22,1,[]],\"friendly\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/security/compliance-status.hbs"
    }
  });

  _exports.default = _default;
});