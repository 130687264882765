define("zerorisk-frontend/helpers/dynamic-params", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dynamicParams = dynamicParams;
  _exports.default = void 0;

  function dynamicParams(_ref
  /*, hash*/
  ) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        routeName = _ref2[0],
        params = _ref2[1];

    return [routeName, {
      isQueryParams: true,
      values: params
    }];
  }

  var _default = Ember.Helper.helper(dynamicParams);

  _exports.default = _default;
});