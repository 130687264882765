define("zerorisk-frontend/templates/subscription/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tIFzllvD",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[22,0,[\"isLoading\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\"],[11,\"class\",\"m-t-50\"],[9],[0,\"\\n\\t\\t\"],[1,[21,\"loading-spinner\"],false],[0,\"\\n\\t\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[4,\"if\",[[27,\"can\",[\"see subscription in application\"],null]],null,{\"statements\":[[0,\"\\t\"],[15,[22,0,[\"activeStepTemplate\"]],[]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\"],[11,\"class\",\"container p-t-30\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"card card-default box-shadow-spread padding-20\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h3\"],[11,\"class\",\"bold\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"i\"],[11,\"class\",\"fa fa-warning m-r-5\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\" \"],[1,[27,\"t\",[\"online_registration.heads_up\"],null],false],[0,\"\\n\\t\\t\\t\\t\"],[10],[0,\"\\n\\t\\t\\t\\tNot enough privileges to see this page.\\n\\t\\t\\t\"],[10],[0,\"\\n\\t\\t\"],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":true}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/subscription/edit.hbs"
    }
  });

  _exports.default = _default;
});