define("zerorisk-frontend/utils/helpers/sort-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isSpecialSymbol = _exports.isNumber = _exports.bubbleSort = void 0;

  /**
   Sort Collection by specified property:
   @method bubbleSort
   @for @ember/utils
   @static
   @param {Array} collection - Collection to sort
   @param {String} property - Property to sort by
   @return {Array} - Sorted collection
   @public
  */
  var bubbleSort = function bubbleSort(collection) {
    var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'code';
    var memo = null;

    if (!collection.length) {
      return collection;
    }

    for (var outer = 0; outer < collection.length; outer++) {
      for (var inner = 0; inner < collection.length; inner++) {
        if (compare(collection[outer][property], collection[inner][property]) == -1) {
          memo = collection[outer];
          collection[outer] = collection[inner];
          collection[inner] = memo;
        }
      }
    }

    return collection;
  };
  /**
   Predicate, to check if value is number
   @method isNumber
   @for @ember/utils
   @static
   @param {Number|String} value - Value to check
   @return {Boolean} - Returns predicate
   @public
  */


  _exports.bubbleSort = bubbleSort;

  var isNumber = function isNumber(value) {
    return !isNaN(value) && Ember.typeOf(value) === 'number';
  };

  _exports.isNumber = isNumber;
  var REGEXP_SPECIAL_CHAR = /^[A-Za-z]\d$/;
  /**
   Predicate, to check if value is number
   @method isSpecialSymbol
   @for @ember/utils
   @static
   @param {Object} v First value to validate
   @param {Object} w Second value to validate
   @return {Boolean} - Returns predicate to check if value matches ("A1", "A2", "A1.1.1", "A2.2.a" etc)
   @public
  */

  var isSpecialSymbol = function isSpecialSymbol(v, w) {
    return new RegExp(REGEXP_SPECIAL_CHAR).test(v) || new RegExp(REGEXP_SPECIAL_CHAR).test(w);
  };
  /**
   Compares two javascript values and returns:
   @method compare
   @for @ember/utils
   @static
   @param {Object} v First value to compare
   @param {Object} w Second value to compare
   @return {Number} -1 if v < w, 0 if v = w and 1 if v > w.
   @public
  */


  _exports.isSpecialSymbol = isSpecialSymbol;

  var compare = function compare(v, w) {
    var vCollection = v.split('.');
    var wCollection = w.split('.');
    var depth = Math.min(vCollection.length, wCollection.length);
    var res = 0;

    for (var i = 0; i < depth; i++) {
      var vElement = vCollection[i];
      var wElement = wCollection[i];
      var shouldCompareNumbers = isNumber(Number(vElement)) && isNumber(Number(wElement));
      var shouldCompareChars = isSpecialSymbol(vElement, wElement) || !isNumber(Number(vElement)) && !isNumber(Number(wElement));

      if (shouldCompareNumbers) {
        if (Number(vElement) < Number(wElement)) {
          res = -1;
          break;
        } else if (Number(vElement) > Number(wElement)) {
          res = 1;
          break;
        }
      } else if (shouldCompareChars) {
        if (vElement < wElement) {
          res = -1;
          break;
        } else if (vElement > wElement) {
          res = 1;
          break;
        }
      } else {
        if (vElement < wElement) {
          res = 1;
          break;
        } else if (vElement > wElement) {
          res = -1;
          break;
        }
      }
    }

    if (res == 0) {
      // check depth
      if (vCollection.length > wCollection.length) {
        res = 1;
      } else if (vCollection.length < wCollection.length) {
        res = -1;
      } else {
        res = 0;
      }
    }

    return res;
  };
});