define("zerorisk-frontend/templates/components/modals/subscription/devices-hint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VLoQQa5C",
    "block": "{\"symbols\":[\"@onAddAddon\"],\"statements\":[[4,\"ember-remodal\",null,[[\"forService\",\"name\"],[true,\"devices-hint\"]],{\"statements\":[[0,\"  \"],[7,\"header\"],[11,\"class\",\"modal-header\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"fa fa-info-circle m-r-5\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n      \"],[1,[27,\"t\",[\"subscription.modals.device_hint.question\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"modal-body rounded-b-corners\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"p-t-10 p-b-25\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"subscription.modals.device_hint.description\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"p-t-10 p-b-10 saq-download-buttons\"],[9],[0,\"\\n        \"],[7,\"div\"],[9],[0,\"\\n          \"],[7,\"button\"],[11,\"class\",\"btn btn-lg bold btn-rounded btn-complete\"],[11,\"style\",\"margin-bottom: 0;\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"subscription.buttons.add_device\"],null],false],[0,\"\\n          \"],[3,\"action\",[[22,0,[]],[27,\"pipe-action\",[[27,\"action\",[[22,0,[]],[22,0,[\"closeModal\"]]],null],[27,\"action\",[[22,0,[]],[22,1,[]]],null]],null]]],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/modals/subscription/devices-hint.hbs"
    }
  });

  _exports.default = _default;
});