define("zerorisk-frontend/templates/components/file-tools/devices-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Azj6WWuu",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[22,0,[\"filePendingToUpload\"]]],null,{\"statements\":[[4,\"file-tools/uploader\",null,[[\"mimeTypes\",\"onfileadd\"],[[22,0,[\"mimeTypes\"]],[27,\"action\",[[22,0,[]],\"onfileadd\"],null]]],{\"statements\":[[0,\"\\n    \"],[7,\"h3\"],[11,\"class\",\"text-center file-uploader-title\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"files.newfile.upload_file\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-group-default box-shadow-spread no-margin p-t-25 p-l-25 p-b-25 p-r-25\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"form-control d-flex align-items-center\"],[11,\"style\",\"font-size: 15px;white-space: nowrap;color:#474951;\"],[11,\"type\",\"text\"],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"fa fa-2x fa-folder m-r-10 text-grey file-icon-color\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n        \"],[7,\"b\"],[11,\"class\",\"m-r-5\"],[9],[1,[27,\"t\",[\"saq.editor.uploader.editor.file_selected.label\"],null],false],[0,\":\"],[10],[0,\"\\n        \"],[1,[22,0,[\"filePendingToUpload\",\"name\"]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"file-uploader-progress\"],[9],[0,\"\\n   \"],[1,[27,\"loading-spinner\",null,[[\"text\",\"containerClass\"],[[27,\"t\",[\"app.uploading\"],null],\"bg-transparent\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/file-tools/devices-uploader.hbs"
    }
  });

  _exports.default = _default;
});