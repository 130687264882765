define("zerorisk-frontend/utils/constants/compromise-task-categorykeys", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.COMPROMISE_TASK_CATEGORYKEYS = void 0;

  var _COMPROMISE_TASK_CATE;

  var COMPROMISE_TASK_CATEGORYKEYS = {
    INITIAL_INVESTIGATION: 'INITIAL_INVESTIGATION',
    PROVIDE_CARDDATA: 'PROVIDE_CARDDATA',
    NOTIFICATION: 'NOTIFICATION',
    FORENSIC: 'FORENSIC',
    REMEDIATION: 'REMEDIATION',
    ONGOING: 'ONGOING'
  };
  _exports.COMPROMISE_TASK_CATEGORYKEYS = COMPROMISE_TASK_CATEGORYKEYS;

  var _default = (_COMPROMISE_TASK_CATE = {}, (0, _defineProperty2.default)(_COMPROMISE_TASK_CATE, COMPROMISE_TASK_CATEGORYKEYS.INITIAL_INVESTIGATION, {
    friendly: 'Initial Investigation',
    bgColor: 'bg-primary',
    textColor: 'text-white-darker'
  }), (0, _defineProperty2.default)(_COMPROMISE_TASK_CATE, COMPROMISE_TASK_CATEGORYKEYS.PROVIDE_CARDDATA, {
    friendly: 'Provide Card Data',
    bgColor: 'bg-danger',
    textColor: 'text-white-darker'
  }), (0, _defineProperty2.default)(_COMPROMISE_TASK_CATE, COMPROMISE_TASK_CATEGORYKEYS.NOTIFICATION, {
    friendly: 'Notification',
    bgColor: 'bg-info',
    textColor: 'text-white-darker'
  }), (0, _defineProperty2.default)(_COMPROMISE_TASK_CATE, COMPROMISE_TASK_CATEGORYKEYS.FORENSIC, {
    friendly: 'Forensic',
    bgColor: 'bg-complete',
    textColor: 'text-master'
  }), (0, _defineProperty2.default)(_COMPROMISE_TASK_CATE, COMPROMISE_TASK_CATEGORYKEYS.REMEDIATION, {
    friendly: 'Remediation',
    bgColor: 'bg-success',
    textColor: 'text-white-darker'
  }), (0, _defineProperty2.default)(_COMPROMISE_TASK_CATE, COMPROMISE_TASK_CATEGORYKEYS.ONGOING, {
    friendly: 'Ongoing',
    bgColor: 'bg-warning',
    textColor: 'text-master'
  }), _COMPROMISE_TASK_CATE); // $color-success: #10cfbd !default;
  // $color-complete : #48b0f7 !default;
  // $color-primary: #6d5cae !default;
  // $color-warning: #f8d053 !default;
  // $color-danger: #f55753 !default;
  // $color-info: #3b4752 !default;


  _exports.default = _default;
});