define("zerorisk-frontend/templates/components/text-wizard/none-answer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MdWgN/hG",
    "block": "{\"symbols\":[\"@answers\",\"@answer\"],\"statements\":[[7,\"div\"],[11,\"style\",\"width: 50%; text-align: center; margin: 0 auto;\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn btn-complete btn-lg btn-larger btn-xxl btn-rounded bold\"],[11,\"width\",\"150px\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"app.button_next\"],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],[22,2,[]],[22,1,[\"yesBringsTo\"]]]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/text-wizard/none-answer.hbs"
    }
  });

  _exports.default = _default;
});