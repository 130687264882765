define("zerorisk-frontend/mirage/models/entity", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Model.extend({
    parent: (0, _emberCliMirage.hasMany)('entity', {
      inverse: 'children',
      async: true
    }),
    children: (0, _emberCliMirage.hasMany)('entity', {
      inverse: 'parent',
      async: true
    }),
    users: (0, _emberCliMirage.hasMany)('user', {
      async: true
    }),
    scans: (0, _emberCliMirage.hasMany)('scan'),
    assetIps: (0, _emberCliMirage.hasMany)('asset-ip'),
    contactInfo: (0, _emberCliMirage.belongsTo)()
  });

  _exports.default = _default;
});