define("zerorisk-frontend/templates/security-risk-rating/assessment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B4PGKRAo",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"containers/merchants/assessment-index\",null,[[\"model\",\"reportInfo\",\"scoresAndReccomendations\",\"businessDetails\",\"surfaceComplexity\",\"accuracy\",\"technologyAssessments\",\"certificates\",\"mxRecord\",\"rootDomainExposure\",\"domainsSecurity\",\"plugins\",\"riskyTechnologies\",\"ipsSecurity\",\"goBack\"],[[22,0,[\"model\"]],[22,0,[\"reportInfo\"]],[22,0,[\"scoresAndReccomendations\"]],[22,0,[\"businessDetails\"]],[22,0,[\"surfaceComplexity\"]],[22,0,[\"accuracy\"]],[22,0,[\"technologyAssessments\"]],[22,0,[\"certificates\"]],[22,0,[\"mxRecord\"]],[22,0,[\"rootDomainExposure\"]],[22,0,[\"domainsSecurity\"]],[22,0,[\"plugins\"]],[22,0,[\"plugins\",\"riskyTechnologies\"]],[22,0,[\"ipsSecurity\"]],[27,\"action\",[[22,0,[]],\"goBack\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/security-risk-rating/assessment.hbs"
    }
  });

  _exports.default = _default;
});