define("zerorisk-frontend/templates/components/pg-main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PMdMth6F",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/pg-main.hbs"
    }
  });

  _exports.default = _default;
});