define("zerorisk-frontend/templates/components/entity-tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "daT497g6",
    "block": "{\"symbols\":[\"child\",\"@parent\",\"@children\"],\"statements\":[[1,[27,\"t\",[\"app.entity\"],null],false],[0,\" \"],[1,[22,2,[\"id\"]],false],[0,\"\\n\\t\"],[7,\"ul\"],[11,\"class\",\"rows\"],[9],[0,\"\\n\"],[4,\"each\",[[22,3,[]]],null,{\"statements\":[[0,\"\\t    \"],[1,[27,\"entity-tree\",null,[[\"parent\",\"children\",\"tagName\"],[[22,1,[]],[22,1,[\"children\"]],\"li\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/entity-tree.hbs"
    }
  });

  _exports.default = _default;
});