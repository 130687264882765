define("zerorisk-frontend/templates/components/search-flow/dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aiqcHSfh",
    "block": "{\"symbols\":[\"option\",\"@options\",\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"search-flow_dropdown__arrow\"],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"search-flow_dropdown\"],[9],[0,\"\\n\"],[4,\"if\",[[22,0,[\"hasNoOption\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"search-flow_dropdown-no-results\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"app.no_results\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[22,2,[]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[12,\"onmouseenter\",[27,\"action\",[[22,0,[]],\"optionHovered\",[22,1,[]]],null]],[12,\"class\",[28,[\"search-flow_dropdown-option \",[27,\"if\",[[22,1,[\"isActive\"]],\"search-flow_dropdown-option-active\"],null]]]],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"fs-13 bold\"],[9],[0,\" \"],[1,[27,\"enum-to-friendly\",[[22,1,[\"title\"]]],null],false],[0,\" \"],[10],[0,\"\\n\"],[4,\"if\",[[22,1,[\"description\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[11,\"class\",\"hint-text\"],[9],[1,[27,\"t\",[[22,1,[\"description\"]]],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[3,\"action\",[[22,0,[]],\"optionClicked\"]],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}],[10],[0,\"\\n\"],[14,3],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/search-flow/dropdown.hbs"
    }
  });

  _exports.default = _default;
});