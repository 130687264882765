define("zerorisk-frontend/mixins/controllers/entity/assessment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    reportInfo: Ember.computed('model.assessment', function () {
      return this.get('model.assessment.report.data');
    }),
    reportHistory: Ember.computed('reportInfo', function () {
      return this.get('reportInfo.history');
    }),
    reportDetails: Ember.computed('reportInfo', function () {
      return this.get('reportInfo.report');
    }),
    businessDetails: Ember.computed('reportDetails', function () {
      return this.get('reportDetails.businessDetails');
    }),
    accuracy: Ember.computed('reportDetails', function () {
      return this.get('reportDetails.accuracy');
    }),
    surfaceComplexity: Ember.computed('reportDetails', function () {
      return this.get('reportDetails.surfaceComplexity');
    }),
    technologyAssessments: Ember.computed('reportDetails', function () {
      return this.get('reportDetails.technologyAssessments');
    }),
    certificates: Ember.computed('reportDetails', function () {
      return this.get('reportDetails.certificates');
    }),
    mxRecord: Ember.computed('reportDetails', function () {
      return this.get('reportDetails.mxRecord');
    }),
    rootDomainExposure: Ember.computed('reportDetails', function () {
      return this.get('reportDetails.rootDomainExposure');
    }),
    domainsSecurity: Ember.computed('reportDetails', function () {
      return this.get('reportDetails.domainsSecurity');
    }),
    ipsSecurity: Ember.computed('reportDetails', function () {
      return this.get('reportDetails.ipsSecurity');
    }),
    plugins: Ember.computed('reportDetails', function () {
      return this.get('reportDetails.plugins');
    }),
    summarizedFindings: Ember.computed('reportDetails', function () {
      return this.get('reportDetails.summarizedFindings');
    }),
    reccomendations: Ember.computed('reportDetails', function () {
      return this.get('reportDetails.reccomendations');
    }),
    scoresAndReccomendations: Ember.computed('reccomendations', function () {
      return this.get('reccomendations.scoresAndReccomendations');
    }),
    actions: {
      goBack: function goBack() {
        window.history.go(-1);
      }
    }
  });

  _exports.default = _default;
});