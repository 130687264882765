define("zerorisk-frontend/templates/components/admin/users/skeleton-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RklpHK3U",
    "block": "{\"symbols\":[\"number\",\"@repeats\"],\"statements\":[[4,\"each\",[[27,\"range\",[0,[22,2,[]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"tr\"],[11,\"class\",\"highlight-on-hover\"],[9],[0,\"\\n    \"],[7,\"td\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"animated-background darker\"],[11,\"style\",\"opacity: .1; height: 19px; width: 100px; background-size: 800px 180px;\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"td\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"animated-background darker\"],[11,\"style\",\"opacity: .1; height: 19px; width: 150px;  background-size: 800px 180px;\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"td\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"animated-background darker\"],[11,\"style\",\"opacity: .1; height: 19px; width: 150px;  background-size: 800px 180px;\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"td\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"animated-background darker\"],[11,\"style\",\"opacity: .1; height: 19px; width: 120px;  background-size: 800px 180px;\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"td\"],[11,\"class\",\"padding-20\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"style\",\"opacity: .1; height: 19px; width: 10px;  background-size: 800px 180px;\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/admin/users/skeleton-view.hbs"
    }
  });

  _exports.default = _default;
});