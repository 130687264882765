define("zerorisk-frontend/utils/constants/compromise-workflow-types", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.COMPROMISE_WORKFLOW_TYPES = void 0;

  var _COMPROMISE_WORKFLOW_;

  var COMPROMISE_WORKFLOW_TYPES = {
    FULL_PFI: 'FULL_PFI',
    VISA_LITE_PFI_CONDITION_1: 'VISA_LITE_PFI_CONDITION_1',
    VISA_LITE_PFI_CONDITION_2: 'VISA_LITE_PFI_CONDITION_2',
    MASTERCARD_ALTERNATE_PFI: 'MASTERCARD_ALTERNATE_PFI',
    MASTERCARD_SELF: 'MASTERCARD_SELF',
    UNDEFINED: 'UNDEFINED'
  };
  _exports.COMPROMISE_WORKFLOW_TYPES = COMPROMISE_WORKFLOW_TYPES;

  var _default = (_COMPROMISE_WORKFLOW_ = {}, (0, _defineProperty2.default)(_COMPROMISE_WORKFLOW_, COMPROMISE_WORKFLOW_TYPES.FULL_PFI, {
    friendly: 'FULL PFI',
    labelClass: 'label-inverse'
  }), (0, _defineProperty2.default)(_COMPROMISE_WORKFLOW_, COMPROMISE_WORKFLOW_TYPES.VISA_LITE_PFI_CONDITION_1, {
    friendly: 'VISA LITE PFI FOR LEVEL 4 MERCHANTS',
    labelClass: 'label-dark'
  }), (0, _defineProperty2.default)(_COMPROMISE_WORKFLOW_, COMPROMISE_WORKFLOW_TYPES.VISA_LITE_PFI_CONDITION_2, {
    friendly: 'VISA LITE PFI FOR eTD ENABLED MERCHANTS',
    labelClass: 'label-dark'
  }), (0, _defineProperty2.default)(_COMPROMISE_WORKFLOW_, COMPROMISE_WORKFLOW_TYPES.MASTERCARD_ALTERNATE_PFI, {
    friendly: 'MASTERCARD ALTERNATE PFI',
    labelClass: 'label-dark'
  }), (0, _defineProperty2.default)(_COMPROMISE_WORKFLOW_, COMPROMISE_WORKFLOW_TYPES.MASTERCARD_SELF, {
    friendly: 'MASTERCARD SELF PFI',
    labelClass: 'label-dark'
  }), (0, _defineProperty2.default)(_COMPROMISE_WORKFLOW_, COMPROMISE_WORKFLOW_TYPES.UNDEFINED, {
    friendly: 'Pending Activation',
    labelClass: 'label-PENDING'
  }), _COMPROMISE_WORKFLOW_);

  _exports.default = _default;
});