define("zerorisk-frontend/templates/components/assessments/report/risk-rating/score-and-reccomendation-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FfReYJad",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"col-sm-12 col-md-2\"],[9],[0,\"\\n  \"],[7,\"h6\"],[9],[1,[27,\"t\",[\"mpa.merchants.assessment.report.summary_findings.table.headers.risk_paramater\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"col-sm-12 col-md-2\"],[9],[0,\"\\n  \"],[7,\"h6\"],[9],[1,[27,\"t\",[\"mpa.merchants.assessment.report.summary_findings.table.headers.risk_rating\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"col-sm-12 col-md-6\"],[9],[0,\"\\n  \"],[7,\"h6\"],[9],[1,[27,\"t\",[\"mpa.merchants.assessment.report.summary_findings.table.headers.risk_reccomendations\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"col-sm-12 col-md-2\"],[9],[0,\"\\n  \"],[7,\"h6\"],[9],[1,[27,\"t\",[\"mpa.merchants.assessment.report.summary_findings.table.headers.pci_violations\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/assessments/report/risk-rating/score-and-reccomendation-header.hbs"
    }
  });

  _exports.default = _default;
});