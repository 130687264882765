define("zerorisk-frontend/utils/constants/compromise-statuses", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.COMPROMISE_STATUSES = void 0;

  var _COMPROMISE_STATUSES$;

  var COMPROMISE_STATUSES = {
    CREATED: 'CREATED',
    IN_PROGRESS: 'IN_PROGRESS',
    UNDER_REVIEW: 'UNDER_REVIEW',
    RESOLVED: 'RESOLVED',
    ARCHIVED: 'ARCHIVED'
  };
  _exports.COMPROMISE_STATUSES = COMPROMISE_STATUSES;

  var _default = (_COMPROMISE_STATUSES$ = {}, (0, _defineProperty2.default)(_COMPROMISE_STATUSES$, COMPROMISE_STATUSES.CREATED, {
    friendly: 'Pending Activation',
    labelClass: 'label-PENDING'
  }), (0, _defineProperty2.default)(_COMPROMISE_STATUSES$, COMPROMISE_STATUSES.IN_PROGRESS, {
    friendly: 'In Progress',
    labelClass: 'label-info'
  }), (0, _defineProperty2.default)(_COMPROMISE_STATUSES$, COMPROMISE_STATUSES.UNDER_REVIEW, {
    friendly: 'Under Review',
    labelClass: 'label-PENDING'
  }), (0, _defineProperty2.default)(_COMPROMISE_STATUSES$, COMPROMISE_STATUSES.RESOLVED, {
    friendly: 'Resolved',
    labelClass: 'label-success'
  }), (0, _defineProperty2.default)(_COMPROMISE_STATUSES$, COMPROMISE_STATUSES.ARCHIVED, {
    friendly: 'Archived',
    labelClass: ''
  }), _COMPROMISE_STATUSES$);

  _exports.default = _default;
});