define("zerorisk-frontend/mixins/perfect-scroll-controller", ["exports", "ember-perfect-scroll/mixins/perfect-scroll-controller"], function (_exports, _perfectScrollController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _perfectScrollController.default;
    }
  });
});