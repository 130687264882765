define("zerorisk-frontend/templates/components/error-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3arVQXCC",
    "block": "{\"symbols\":[\"@errors\"],\"statements\":[[4,\"if\",[[22,1,[]]],null,{\"statements\":[[0,\"  \"],[1,[22,1,[]],true],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/error-box.hbs"
    }
  });

  _exports.default = _default;
});