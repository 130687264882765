define("zerorisk-frontend/templates/components/risk-score-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H4M/ICAC",
    "block": "{\"symbols\":[\"@scoreType\",\"@scoreValue\"],\"statements\":[[7,\"span\"],[11,\"class\",\"text-nowrap\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"m-r-5\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"fs-12 label no-border-right py-1 pl-3\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[[27,\"concat\",[\"mpa.merchant.security.\",[23,[\"scoreType\"]]],null]],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"span\"],[12,\"class\",[28,[\"fs-12 label no-border-left py-1 pl-3 pr-3 \",[27,\"get-grade-score-color\",[[22,2,[]],[22,1,[]]],null]]]],[9],[0,\"\\n      \"],[7,\"span\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"gte\",[[23,[\"scoreValue\"]],0],null]],null,{\"statements\":[[0,\"          \"],[1,[21,\"scoreValue\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          -\\n\"]],\"parameters\":[]}],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/risk-score-label.hbs"
    }
  });

  _exports.default = _default;
});