define("zerorisk-frontend/utils/constants/merchant-statuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    Compliant: {
      friendly: 'Compliant',
      icon: 'check-circle-o',
      textClass: 'text-success',
      labelClass: 'label-success',
      bgClass: 'bg-success',
      hsl: '#10cfbd'
    },
    NotCompliant: {
      friendly: 'Not Compliant',
      icon: 'times-circle-o',
      textClass: 'text-danger',
      labelClass: 'label-danger',
      bgClass: 'bg-danger',
      hsl: '#f55753'
    },
    Enrolled: {
      friendly: 'Enrolled',
      icon: 'dot-circle-o',
      textClass: 'text-complete',
      labelClass: 'label-info',
      bgClass: 'bg-complete',
      hsl: '#48b0f7'
    },
    NotEnrolled: {
      friendly: 'Not Enrolled',
      icon: 'circle-o',
      textClass: 'text-master',
      labelClass: '',
      bgClass: 'bg-master',
      hsl: '#626262'
    },
    Closed: {
      friendly: 'Closed',
      icon: 'circle',
      textClass: 'text-primary',
      labelClass: 'label-primary',
      bgClass: 'bg-secondary',
      hsl: '#6d5cae'
    },
    Compromised: {
      friendly: 'Compromised',
      icon: 'circle',
      textClass: 'text-dark',
      labelClass: 'label-dark',
      bgClass: 'bg-secondary',
      hsl: '#17a2b8'
    },
    AuditRequired: {
      friendly: 'Audit Required',
      icon: 'circle',
      textClass: 'text-warning',
      labelClass: 'label-warning',
      bgClass: 'bg-secondary',
      hsl: '#fed76e'
    },
    Undetermined: {
      friendly: 'Undetermined',
      icon: 'circle',
      textClass: 'text-secondary',
      labelClass: 'label-secondary',
      bgClass: 'bg-secondary',
      hsl: '#6c757d'
    }
  };
  _exports.default = _default;
});