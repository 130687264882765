define("zerorisk-frontend/utils/statuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    // Merchant
    Compliant: {
      friendly: 'Compliant',
      icon: 'check-circle-o',
      textClass: 'text-success',
      labelClass: 'label-success',
      bgClass: 'bg-success',
      hsl: '#10cfbd'
    },
    NotCompliant: {
      friendly: 'Not Compliant',
      icon: 'times-circle-o',
      textClass: 'text-danger',
      labelClass: 'label-danger',
      bgClass: 'bg-danger',
      hsl: '#f55753'
    },
    Enrolled: {
      friendly: 'Enrolled',
      icon: 'dot-circle-o',
      textClass: 'text-complete',
      labelClass: 'label-info',
      bgClass: 'bg-complete',
      hsl: '#48b0f7'
    },
    NotEnrolled: {
      friendly: 'Not Enrolled',
      icon: 'circle-o',
      textClass: 'text-master',
      labelClass: '',
      bgClass: 'bg-master',
      hsl: '#626262'
    },
    Closed: {
      friendly: 'Closed',
      icon: 'circle',
      textClass: 'text-primary',
      labelClass: 'label-primary',
      bgClass: 'bg-primary',
      hsl: '#6d5cae'
    },
    Compromised: {
      friendly: 'Compromised',
      icon: 'circle',
      textClass: 'text-dark',
      labelClass: 'label-dark',
      bgClass: 'bg-secondary',
      hsl: '#17a2b8'
    },
    AuditRequired: {
      friendly: 'Audit Required',
      icon: 'circle',
      textClass: 'text-warning',
      labelClass: 'label-warning',
      bgClass: 'bg-secondary',
      hsl: '#fed76e'
    },
    // Device
    IN_STOCK: {
      friendly: 'In Stock',
      description: 'Device is stored somewhere and ready to be installed',
      icon: 'archive',
      labelClass: 'label-info',
      bgClass: 'bg-info',
      pointColorClass: 'complete-light'
    },
    IN_TRANSIT: {
      friendly: 'In Transit',
      description: 'Device is currently in transit towards the target destination',
      icon: 'truck',
      labelClass: 'label-primary',
      bgClass: 'bg-primary',
      pointColorClass: 'master-lightest'
    },
    INSTALLED: {
      friendly: 'Installed',
      description: 'Device is installed and operational',
      icon: 'sign-in',
      labelClass: 'label-success',
      bgClass: 'bg-success',
      pointColorClass: 'success'
    },
    IN_MAINTENANCE: {
      friendly: 'In Maintenance',
      description: 'Device is under repair or maintenance',
      icon: 'exclamation-triangle',
      labelClass: 'label-warning',
      bgClass: 'bg-warning',
      pointColorClass: 'warning-light'
    },
    DISMISSED: {
      friendly: 'Dismissed',
      description: 'Device has been dismissed and is no longer serviceable',
      icon: 'trash',
      labelClass: 'label-primary',
      bgClass: 'bg-primary',
      pointColorClass: 'master-lightest'
    },
    // Scan
    '': {
      friendly: 'Pending',
      labelClass: 'label-primary'
    },
    RUNNING: {
      friendly: 'Running',
      labelClass: 'label-info'
    },
    FINISHED: {
      friendly: 'Finished',
      labelClass: 'label-success'
    },
    QUEUED: {
      friendly: 'Queued',
      labelClass: 'label-primary'
    },
    CANCELED: {
      friendly: 'Canceled',
      labelClass: 'label-danger'
    },
    FAILED: {
      friendly: 'Failed',
      labelClass: 'label-danger'
    },
    PAUSED: {
      friendly: 'Paused',
      labelClass: 'label-warning'
    }
  };
  _exports.default = _default;
});