define("zerorisk-frontend/templates/components/merchants/filters/steps/value-step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hF+yMI5E",
    "block": "{\"symbols\":[\"option\",\"@filter\",\"@applyFilter\"],\"statements\":[[7,\"div\"],[11,\"class\",\"options-section\"],[9],[0,\"\\n\"],[4,\"if\",[[22,0,[\"hasOptions\"]]],null,{\"statements\":[[4,\"if\",[[22,0,[\"remoteOptions\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"input-section\"],[9],[0,\"\\n        \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"class\",\"placeholder\"],[\"text\",[22,0,[\"searchValue\"]],\"form-control bg-transparent\",[27,\"t\",[\"app.type_to_search\"],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"each\",[[22,0,[\"availableOptions\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[12,\"class\",[28,[\"step-option-item \",[27,\"if\",[[27,\"eq\",[[22,0,[\"selectedItem\"]],[22,1,[\"value\"]]],null],\"active\"],null]]]],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"fs-13 bold\"],[9],[0,\"\\n          \"],[1,[27,\"log\",[[22,1,[]]],null],false],[0,\"\\n          \"],[1,[27,\"enum-to-friendly\",[[22,1,[\"title\"]]],null],false],[0,\"\\n        \"],[10],[0,\"\\n\\n\"],[0,\"      \"],[3,\"action\",[[22,0,[]],\"optionSelected\",[22,1,[\"value\"]],[22,1,[\"title\"]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"empty-state\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"app.no_results\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"app.no_results\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"after-options buttons-section\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn-sm btn btn-complete\"],[12,\"disabled\",[27,\"not\",[[22,2,[\"value\"]]],null]],[9],[0,\"\\n    Apply\\n  \"],[3,\"action\",[[22,0,[]],[22,3,[]]]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/merchants/filters/steps/value-step.hbs"
    }
  });

  _exports.default = _default;
});