define("zerorisk-frontend/language", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Language = {
    messages: {
      unexpected_server_error: "Something unexpected happened. Please contact our support by clicking the 'Help' button on the bottom."
    },
    roles: {
      ROLE_PINPOINT_MANAGER: 'Manager',
      ROLE_PINPOINT_AUDITOR: 'Auditor',
      ROLE_PINPOINT_ADMIN: 'Admin'
    }
  };
  var _default = Language;
  _exports.default = _default;
});