define("zerorisk-frontend/helpers/audit-survey-answer-formatter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.auditSurveyAnswerFormatter = auditSurveyAnswerFormatter;
  _exports.default = void 0;

  function auditSurveyAnswerFormatter(answer) {
    var _answer = answer[0];

    if (_answer === 1 || _answer === '1') {
      return new Ember.String.htmlSafe('Yes');
    } else if (_answer === 0 || _answer === '0') {
      return new Ember.String.htmlSafe('No');
    } else {
      return new Ember.String.htmlSafe(_answer);
    }
  }

  var _default = Ember.Helper.helper(auditSurveyAnswerFormatter);

  _exports.default = _default;
});